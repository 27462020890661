

import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Form,
  Alert,
  Modal,
  Button,
  InputGroup,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./Signup.css";

function Signup({domain}) {
  const startYear = 1901;
  const endYear = new Date().getFullYear();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState("");
  const [isPhoneEditable, setIsPhoneEditable] = useState(false);
  const [timer, setTimer] = useState(30); // 30 seconds timer
  const [canResend, setCanResend] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpError, setOtpError] = useState("");
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  let [username, setUsername] = useState(sessionStorage.getItem("username"));
  let [email, setEmail] = useState(sessionStorage.getItem("email"));
  console.log(username, email);

  useEffect(() => {
    if (timer > 0) {
      const intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    } else {
      setCanResend(true);
    }
  }, [timer]);

  let [allpoll, setAllpoll] = useState([]);
  useEffect(() => {
    axios.get(`${domain}api/getall`).then((res) => {
      console.log(res.data.users);
      setAllpoll(res.data);
      console.log(allpoll);
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      day: "",
      month: "",
      year: "",
      gender: "",
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string()
        .min(3, "First name must be at least 3 characters")
        .matches(/^[A-Za-z\s]+$/, "First name should only contain alphabets")
        .required("First name is required"),
      lastname: Yup.string()
        .min(1, "Last name is required")
        .matches(/^[A-Za-z\s]+$/, "Last name should only contain alphabets")
        .required("Last name is required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required")
        .test("email", "Invalid email", function (value) {
          const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          return emailRegex.test(value);
        }),
      phone: Yup.string()
        .required("Phone number is required")
        .test(
          "checkphonenumber",
          "Phone number already exists",
          async (value) => {
            const phoneExists =
              allpoll &&
              allpoll.users.some((poll) => poll.phone_number === value);
            return !phoneExists;
          }
        ),
      day: Yup.string().required("Day is required"),
      month: Yup.string().required("Month is required"),
      year: Yup.string().required("Year is required"),
      gender: Yup.string().required("Gender is required"),
      password: Yup.string()
        .matches(/^\d{6}$/, "Must be 6 digits")
        .min(6, "Password must be at 6 digits")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    validateOnBlur: true,
    onSubmit: (values) => {
      console.log(otpVerified);
      let existingemail = allpoll.users.some(
        (item) => item.email == formik.values.email
      );
      console.log(existingemail);
      let existingphonenumber = allpoll.users.some(
        (item) => item.phone_number == formik.values.phone
      );
      console.log(existingphonenumber);
     
      if (otpVerified) {
        if (existingemail || existingphonenumber) {
          alert("Already a user");
          navigate("/Loginpg");
        } else {
          handleSignup(values);
        }
      } else {
        setOtpError("Please verify OTP.");
      }
    },
  });

 
  const handleSignup = (values) => {
    console.log("signup");
    let dob = new Date(
      formik.values.year,
      formik.values.month - 1,
      formik.values.day
    );

    console.log(formik.values.year);
    console.log(dob);
    const formattedDate = new Date(dob);
    console.log("Formatted Date:", formattedDate);

    let currentdate = new Date();
    let age1 = currentdate.getFullYear() - dob.getFullYear();
    let formattedDob = dob.toISOString().split("T")[1];
    console.log(formattedDob);

    let newphone = parseInt(formik.values.phone);
    let signupdata = {
      user_name: `${formik.values.firstname} ${formik.values.lastname}`,
      email: formik.values.email,
      phone_number: formik.values.phone,
      gender: formik.values.gender,
      age: age1,
      password: formik.values.password,
     
    };
    console.log(signupdata);

    axios
      .post(`${domain}api/createuser`, signupdata)
      .then((response) => {
        alert("Created successfully");
        setShowAlert(true);
        setTimeout(() => {
          navigate("/Loginpg");
        }, 1500);
      })
      .catch((error) => {
        console.error(
          "Error during signup:",
          error.response ? error.response.data : error.message
        );
        alert("Signup failed. Please try again.");
      });
  };

  const sendOtp = async () => {
    if (formik.values.phone && !formik.errors.phone) {
      try {
        const response = await fetch(
          `${domain}mobileauth/send-otp-sms`,
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ number: formik.values.phone }),
          }
        );

        if (response.ok) {
          console.log("OTP sent successfully");
          setOtpSent(true);
          setTimer(40); // Reset the timer
          setCanResend(false); // Disable the resend button
          setShowModal(true); // Open OTP Modal
        } else {
          console.error("Failed to send OTP");
        }
      } catch (error) {
        console.error("Error sending OTP:", error);
      }
    } else {
      setOtpError("Please enter a valid phone number first.");
    }
  };

  const verifyOtp = async () => {
    try {
      console.log(otp);
      const response = await axios.post(`${domain}mobileauth/verify-otp-sms`,
        {
          number: formik.values.phone,
          otp: otp,
        }
      );

      if (response.status == 200) {
        setOtpVerified(true);
        setOtpError("");
        setShowModal(false);
      } else {
        setOtpError("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setOtpError("Error verifying OTP. Please try again.");
    }
  };

  const handleResendOtp = () => {
    sendOtp();
  };

  const handleLoginClick = () => {
    navigate("/Loginpg");
  };

  return (
    <div className="signup-container">
      <br />
      <Row>
        <Col sm={4}></Col>
        <Col sm={4}>
          <Card className="x1">
            <Card.Header>
              <Row>
                <Col sm={11}>
                  <div className="text-center">
                    <h3 style={{ textAlign: "center" }} className="x2">
                      Create your account
                    </h3>
                  </div>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body>
              {showAlert && (
                <Alert
                  variant="success"
                  onClose={() => setShowAlert(false)}
                  dismissible
                >
                  Sign up successful!
                </Alert>
              )}
              <Form onSubmit={formik.handleSubmit}>
                {/* Firstname */}
                <div>
                  <Row>
                    <Col sm={6}>
                      <Form.Control
                        type="text"
                        placeholder="Enter first name"
                        {...formik.getFieldProps("firstname")}
                        isInvalid={
                          formik.touched.firstname && formik.errors.firstname
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.firstname}
                      </Form.Control.Feedback>
                    </Col>

                    {/* Lastname */}
                    <Col sm={6}>
                      <Form.Control
                        type="text"
                        placeholder="Enter last name"
                        {...formik.getFieldProps("lastname")}
                        isInvalid={
                          formik.touched.lastname && formik.errors.lastname
                        }
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.lastname}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                </div>
                {/* Email */}
                <Form.Control
                  type="text"
                  style={{ marginTop: "10px" }}
                  placeholder="Email address"
                  className="x4"
                  {...formik.getFieldProps("email")}
                  isInvalid={formik.touched.email && formik.errors.email}
                  onBlur={formik.handleBlur}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.email}
                </Form.Control.Feedback>
                {/* Phone */}
                <div className="phone-input-container">
                  <Row>
                    <Col sm={2}>
                      <Form.Control
                        type="text"
                        placeholder="+91"
                        defaultValue="+91"
                        disabled
                        className="x4"
                      />
                    </Col>
                    <Col sm={8}>
                      <Form.Control
                        type="text"
                        placeholder="Phone number"
                        className="x4"
                        {...formik.getFieldProps("phone")}
                        isInvalid={formik.touched.phone && formik.errors.phone}
                        onBlur={formik.handleBlur}
                        maxLength={10}
                        disabled={otpVerified && !isPhoneEditable} // Enable editing only when "Edit" is clicked
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.phone}
                      </Form.Control.Feedback>
                    </Col>
                    <Col sm={2}>
                      {" "}
                      {otpVerified && (
                        <div>
                          <span
                            style={{ color: "green" }}
                            className="verified-label"
                          >
                            Verified
                          </span>
                          {/* <span>
                        <button>edit</button>
                      </span> */}
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
                {/* OTP Verification */}
                {!otpVerified && (
                  <div>
                    {!isPhoneEditable && (
                      <button
                        className="otp-button"
                        type="button"
                        onClick={() => {
                          if (otpSent) {
                            verifyOtp();
                          } else {
                            sendOtp();
                          }
                        }}
                      >
                        {otpSent ? "Verify OTP" : "Send OTP"}
                      </button>
                    )}
                    {/* {otpSent && !otpVerified && (
                      <button
                        className="edit-button"
                        type="button"
                        onClick={() => {
                          setIsPhoneEditable(true); // Enable phone number editing
                          setOtpVerified(false); // Reset OTP verification
                          setOtpSent(false); // Reset OTP sent status
                        }}
                      >
                        Edit
                      </button>
                    )} */}
                  </div>
                )}
                {/* Phone */}
                {/* <div className="phone-input-container">
                  <Row>
                    <Col sm={3}>
                      <Form.Control
                        type="text"
                        placeholder="+91"
                        defaultValue="+91"
                        disabled
                        className="x4"
                      />
                    </Col>
                    <Col sm={9}>
                      <Form.Control
                        type="text"
                        placeholder="Phone number"
                        className="x4"
                        {...formik.getFieldProps("phone")}
                        isInvalid={formik.touched.phone && formik.errors.phone}
                        onBlur={formik.handleBlur}
                        maxLength={10}
                      />
                      <Form.Control.Feedback type="invalid">
                        {formik.errors.phone}
                      </Form.Control.Feedback>
                    </Col>
                  </Row>
                  {otpVerified && (
                    <span style={{ color: "green" }} className="verified-label">
                      Verified
                    </span>
                  )}
                </div> */}
                {/* OTP Verification */}
                {/* {!otpVerified && (
                  <button
                    className="otp-button"
                    type="button"
                    onClick={() => {
                      if (otpSent) {
                        verifyOtp();
                      } else {
                        sendOtp();
                      }
                    }}
                  >
                    {otpSent ? "Verify OTP" : "Send OTP"}
                  </button>
                )} */}
                {/* Date of Birth */}
                <h6>Date of Birth</h6>
                <Row>
                  <Col className="x5" sm={4}>
                    <Form.Select
                      aria-label="Select Day"
                      {...formik.getFieldProps("day")}
                      value={formik.values.day}
                      isInvalid={formik.touched.day && formik.errors.day}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Day</option>
                      {[...Array(31)].map((_, i) => (
                        <option key={i} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.day}
                    </Form.Control.Feedback>
                  </Col>

                  <Col className="x5" sm={4}>
                    <Form.Select
                      aria-label="Select Month"
                      {...formik.getFieldProps("month")}
                      value={formik.values.month}
                      isInvalid={formik.touched.month && formik.errors.month}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Month</option>
                      {Array.from({ length: 12 }, (_, i) => (
                        <option key={i} value={i + 1}>
                          {new Date(0, i).toLocaleString("en-US", {
                            month: "short",
                          })}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.month}
                    </Form.Control.Feedback>
                  </Col>

                  <Col className="x5" sm={4}>
                    <Form.Select
                      aria-label="Select Year"
                      {...formik.getFieldProps("year")}
                      value={formik.values.year}
                      isInvalid={formik.touched.year && formik.errors.year}
                      onBlur={formik.handleBlur}
                    >
                      <option value="">Year</option>
                      {Array.from(
                        { length: endYear - startYear + 1 },
                        (_, i) => (
                          <option key={i} value={endYear - i}>
                            {endYear - i}
                          </option>
                        )
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {formik.errors.year}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                {/* Gender */}
                <h6>Gender</h6>
                <Row>
                  <Col sm={4} className="a1">
                    <Form.Check
                      // reverse
                      label="Male"
                      name="gender"
                      type="radio"
                      id="reverse-radio-1"
                      value="male"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.gender === "male"}
                      isInvalid={formik.touched.gender && formik.errors.gender}
                    />
                  </Col>
                  <Col sm={4} className="a2">
                    <Form.Check
                      // reverse
                      label="Female"
                      name="gender"
                      type="radio"
                      id="reverse-radio-2"
                      value="female"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.gender === "female"}
                      isInvalid={formik.touched.gender && formik.errors.gender}
                    />
                  </Col>
                  <Col sm={4} className="a">
                    <Form.Check
                      // reverse
                      label="Custom"
                      name="gender"
                      type="radio"
                      id="reverse-radio-3"
                      value="custom"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      checked={formik.values.gender === "custom"}
                      isInvalid={formik.touched.gender && formik.errors.gender}
                    />
                  </Col>
                </Row>
                {formik.touched.gender && formik.errors.gender && (
                  <Form.Control.Feedback
                    type="invalid"
                    style={{ display: "block" }}
                  >
                    {formik.errors.gender}
                  </Form.Control.Feedback>
                )}
                <p></p>
                {/* Password */}
                <InputGroup className="mb-3 password-input-group">
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    placeholder="Enter 6 digits password"
                    className="x4"
                    maxLength={6}
                    {...formik.getFieldProps("password")}
                    isInvalid={
                      formik.touched.password && formik.errors.password
                    }
                    onBlur={formik.handleBlur}
                  />
                  <Button
                    className="toggle-password-btn"
                    variant="outline-secondary"
                    onClick={() => setShowPassword(!showPassword)}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "5px",
                      marginTop: "-10px",
                      color: "grey",
                    }}
                  >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
                {/* Confirm Password */}
                <InputGroup className="mb-3 password-input-group">
                  <Form.Control
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm 6 digits password"
                    className="x4"
                    maxLength={6}
                    {...formik.getFieldProps("confirmPassword")}
                    isInvalid={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    onBlur={formik.handleBlur}
                  />
                  <Button
                    className="toggle-password-btn"
                    variant="outline-secondary"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    style={{
                      border: "none",
                      backgroundColor: "white",
                      marginLeft: "5px",
                      marginTop: "-10px",
                      color: "grey",
                    }}
                  >
                    {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                  </Button>
                  <Form.Control.Feedback type="invalid">
                    {formik.errors.confirmPassword}
                  </Form.Control.Feedback>
                </InputGroup>
                {/* Submit Button */}
                <button className="z10" type="submit">
                  Sign Up
                </button>
                <br />
                {/* Already have an account? */}
                <p style={{ marginBottom: "-2px" }} className="or">
                  or
                </p>
                <div className="text-center">
                  <button
                    type="button"
                    className="z10"
                    onClick={handleLoginClick}
                  >
                    Login
                  </button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={4}></Col>
      </Row>
      <br />
      <br />
      <br />

      {/* OTP Modal */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "black" }}>OTP Verification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formOtp">
            <Form.Label style={{ color: "black" }}>Enter OTP:</Form.Label>
            <Form.Control
              type="text"
              value={otp}
              onChange={(e) => setOtp(e.target.value)}
              maxLength={6}
            />
            {otpError && <p style={{ color: "red" }}>{otpError}</p>}
          </Form.Group>
          <button
            className="verifyotpbut"
            variant="primary"
            onClick={verifyOtp}
          >
            Verify OTP
          </button>
          <div>
            {canResend ? (
              <Button
                style={{ color: "blue" }}
                variant="link"
                onClick={handleResendOtp}
              >
                Resend OTP
              </Button>
            ) : (
              <span style={{ color: "black" }}>
                Resend OTP in {timer} seconds
              </span>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            style={{ backgroundColor: "blue" }}
            variant="secondary"
            onClick={() => setShowModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Signup;
