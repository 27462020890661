

import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Button,
  Col,
  Row,
  Popover,
  Overlay,
  ToastContainer,
  ProgressBar,
} from "react-bootstrap";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageContext } from "../Components/Homepage";
import { toast } from "react-toastify";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { useLocation } from "react-router-dom";

const Userdetails = ({ polls, polluserId, poll, options, optionscount ,domain}) => {
  console.log(options)
  let userId =
    sessionStorage.getItem("loginuserId") ||
    sessionStorage.getItem("googleuserId");
  console.log("userId", userId);
  console.log(polluserId, options, poll, optionscount);

  const [
    page,
    setPage,
    pollid,
    setPollid,
    isFollowing,
    setIsFollowing,
    followStatus,
    setFollowStatus,
    totallike,
    setTotallike,
    liked,
    setLiked,
    likeCount,
    setLikeCount,
    likedPolls,
    setLikedPolls,
    selectedOption,
    setSelectedOption,
    showVoteButton,
    setShowVoteButton,
    hasVoted,
    setHasVoted,
    voteResults,
    setVoteResults,
    totalVotes,
    setTotalVotes,
    hasVotedbutton,
    setHasvotedbutton,
  ] = useContext(PageContext);

  const [userData, setUserData] = useState(""); // State to hold user details
  const [activeTab, setActiveTab] = useState(""); // State to keep track of the active tab
  const [profileImage, setProfileImage] = useState(
    "https://via.placeholder.com/150"
  ); // State to manage profile image
  const [createdpolls, setCreatedpolls] = useState([]); // State to hold created polls
  const [votedpolls, setVotedpolls] = useState([]); // State to hold voted polls

  // const [liked, setLiked] = useState(false); // New state for likes
  // const [likeCount, setLikeCount] = useState(""); // New state for likes count
  // let [totallike, setTotallike] = useState(0);

  // const [liked, setLiked] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false); // State for showing the share overlay
  const target = useRef(null); // Reference for the share button

  //   const [selectedOption, setSelectedOption] = useState(null); // New state for selected option
  //   const [showVoteButton, setShowVoteButton] = useState(false);
  //   const [hasVoted, setHasVoted] = useState(true);
  //   const [voteResults, setVoteResults] = useState([]); // State to hold vote results
  // const [totalVotes, setTotalVotes] = useState(0); // State for total votes
  // const [isFollowing, setIsFollowing] = useState(false); // State for follow/unfollow

  const [phone_number, setPhoneNumber] = useState("");

  const navigate = useNavigate();

  const [googleuser, setgoogleuser] = useState(
    sessionStorage.getItem("username")
  );
  let loginuser = useLocation();
  let newuser = loginuser.state;

  let data = { user_id: userId };
  console.log("data", data);
  // Fetch user details when the component mounts
  useEffect(() => {
    console.log(data);
    axios
      .post(`${domain}api/getProfile`, {
        user_id: userId,
      })
      .then((response) => {
        console.log(response.data);
        setPhoneNumber(response.data.user.phone_number);
        setUserData(response.data);
        if (response.data.profileImage) {
          setProfileImage(response.data.profileImage); // Set the profile image if available in response
        }
      })
      .catch((error) => {
        console.error("There was an error fetching the user details!", error);
      });
  }, []);

  // Function to handle image file input changes
  const handleImageChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      const imageURL = URL.createObjectURL(file); // Create a URL for the selected image
      setProfileImage(imageURL); // Update the profile image state with the new image URL
    }
  };

  // Function to fetch created polls

  const fetchCreatedPolls = () => {
    axios
      .post(`${domain}api/getProfile`, {
        user_id: userId,
      })
      .then((response) => {
        console.log(response.data);
        console.log(response.data.user.created_polls);

        setCreatedpolls(response.data.user.created_polls || []); // Set the fetched data to the state
        setActiveTab("created"); // Update the active tab
      })
      .catch((error) => {
        console.error("There was an error fetching created polls!", error);
      });
  };

  const fetchVotedPolls = () => {
    axios
      .post(`${domain}api/getProfile`, {
        user_id: userId,
      })
      .then((response) => {
        console.log(response.data.user.voted_polls);
        setVotedpolls(response.data.user.voted_polls || []); // Set the fetched data to the state
        setActiveTab("voted"); // Update the active tab
      })
      .catch((error) => {
        console.error("There was an error fetching voted polls!", error);
      });
  };

  useEffect(() => {
    if (poll && poll.createdBy && poll.total_likes) {
      setLiked((prevState) => ({
        ...prevState,
        [poll._id]: poll.createdBy.isLiked,
      }));

      setTotallike((prev) => ({
        ...prev,
        [poll._id]: poll.total_likes,
      }));
    }

    //console.log(liked[poll._id]);
    // console.log({[poll._id]:poll.total_likes});

    console.log(totallike);
  }, [poll]);

  // const toggleLike = () => {
  //   setLikeCount(liked ? likeCount - 1 : likeCount + 1);
  //   setLiked(!liked);
  // };

  const handleLike = (id) => {
    //console.log(createdBy._id);
    // console.log(id)

    axios
      .post(`${domain}polls/likeonpoll`, {
        poll_id: id,
        user_id: userId,
      })

      .then((res) => {
        console.log(res.data);
        setTotallike((prev) => ({
          ...prev,
          [id]: res.data.Total_likes,
        }));
        if (res.data.message === "Like recorded successfully") {
          setLiked((prevState) => ({
            ...prevState,
            [id]: true,
          }));
        } else if (res.data.message === "Like removed successfully") {
          setLiked((prevState) => ({
            ...prevState,
            [id]: false,
          }));
        }
      })
      .catch((err) => {
        console.error("Error in Liking a poll", err);
      });
  };
  let handleOnepoll = (_id) => {
    console.log(_id);
    // navigate('/onepoll/'+_id
    setPage("CommentsComp");
    console.log(page);
    setPollid(_id);
    console.log(page, pollid);
  };

  const handleShareClick = () => {
    setShowOverlay(!showOverlay); // Toggle the overlay visibility
  };

  const handleFollowToggle = (polluserId) => {
    const isFollowing = followStatus[polluserId] || false;

    axios
      .post(`${domain}api/follow`, {
        follow_user_id: polluserId,
        user_id: userId,
        // action: isFollowing ? "unfollow" : "follow",
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.message === "Follower added successfully") {
          setFollowStatus((prevState) => ({
            ...prevState,
            [polluserId]: true,
          }));
        } else if (res.data.message === "Follower removed successfully") {
          setFollowStatus((prevState) => ({
            ...prevState,
            [polluserId]: false,
          }));
        }
      })
      .catch((err) => {
        console.error("Error in follow/unfollow request:", err);
      });
  };
  const fetchTotalVotes = () => {
    axios
      .post(`${domain}polls/totalvote`, {
        poll_id: poll._id,
      })
      .then((response) => {
        const updatedVoteResults = response.data.results || [];
        const updatedTotalVotes = response.data.totalVotes || 0;

        setVoteResults(updatedVoteResults);
        setTotalVotes(updatedTotalVotes);
      })
      .catch((error) => {
        console.error("Error fetching total votes:", error);
      });
  };
  const handleVoteToggle = (poll_id, option,options) => {
    console.log(options)
    console.log(option)
    console.log(options[option].option)
    console.log(poll_id, userId, options[option].option);
    const selectedOptionValue = options[option].option;
    console.log(selectedOptionValue)
if(selectedOptionValue){


  sessionStorage.setItem("polloption",  options[option].option);
}
let polloption=sessionStorage.getItem("polloption")
console.log(polloption)
let data={ poll_id: poll_id,
  user_id: userId,
  option: selectedOptionValue ? selectedOptionValue :polloption }
  console.log(data)
    axios
      .post(`${domain}polls/voteonpoll`, data)
      .then((res) => {
        console.log(res.data.message);

        if (res.data.message === "Vote recorded successfully.") {
          alert("Voted");
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: true,
          }));
        } else if (
          res.data.message === "Vote removed successfully. Please vote again."
        ) {
          alert("Unvoted");
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: false,
          }));
        } else {
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: false,
          }));
        }

        // handleOnepoll(poll_id);
      });
  };

  useEffect(() => {
    if (poll) {
      setHasVoted((prevState) => ({
        ...prevState,
        [poll._id]: poll.voters.some((voter) => voter._id === userId),
      }));
    }
  }, [poll, userId]);

  const handleOptionChange = (index) => {
    if (selectedOption === index) {
      unselectOption(); // Unselect the option if it's already selected
    } else {
      setSelectedOption(index); // Select the option
      setShowVoteButton(true);
    }
  };

  const unselectOption = () => {
    setSelectedOption(null); // Unselect the currently selected option
    setShowVoteButton(false);
  };

  const calculatePercentage = (count,options) => {
    console.log(count)
    console.log(options)
    let totalvotes=options.reduce((a,b)=>a+b.count,0)
    console.log(totalVotes)
    if (totalvotes === 0) return 0;
    return ((count / totalvotes) * 100).toFixed(2); // Return percentage with 2 decimal places
  };

  const handleDeleteAccount = () => {
    console.log("phone_number", phone_number);
    if (window.confirm("Are you sure you want to delete your account?")) {
      axios
        .post(`${domain}/api/deleteuser`, {
          identifier: phone_number,
        })
        .then((response) => {
          if (response.status === 204) {
            alert("Account deleted successfully");
            navigate("/");
          }
          console.log(response.data);
        })
        .catch((error) => {
          console.error("There was an error deleting the account!", error);
          alert("Failed to delete account. Please try again.");
        });
    }
  };

  return (
    <div>
      {/* Profile Section */}
      <Card className="mb-4">
        <Card.Body>
          <div className="text-center">
            {/* Profile Image */}
            <img
              src={profileImage}
              alt="profile"
              className="rounded-circle"
              style={{ width: "150px", height: "150px" }}
            />

            {/* Input to Upload Image */}
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ marginTop: "10px" }}
            />

            {/* Display User Name and Stats */}
            {/* <h5>{userId}</h5>*/}

            <h3>{googleuser ? googleuser : newuser} </h3>
            <p>
              {userData.posts || 0} post | {userData.followers || 0} Follower |{" "}
              {userData.following || 0} Following
            </p>
            {/* <Button variant="primary">Edit Profile</Button> */}
            <button className="btn btn-danger" onClick={handleDeleteAccount}>
              Delete Account
            </button>
          </div>
        </Card.Body>
      </Card>

      {/* Main Content - Polls Section */}
      <Card className="mb-4 usercard">
        <Card.Body>
          <Row>
            <Col sm={6}>
              <Button
                variant="info"
                style={{ marginLeft: "70px" }}
                onClick={fetchCreatedPolls}
                 className="userbuttonleft"
              >
                Created Polls
              </Button>
            </Col>
            <Col sm={6}>
              <Button
                variant="info"
                style={{ marginLeft: "90px" }}
                onClick={fetchVotedPolls}
                className="userbuttonright"
              >
                Voted Polls
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      {/* Display the Fetched Polls */}
      {activeTab === "created" && (
        <div>
          {createdpolls.length > 0 ? (
            createdpolls.map((poll, index) => (
              <Card className="mb-4" key={index}>
                <Card.Body>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6>Name:{poll.createdBy.user_name}</h6>

                      <p
                        style={{
                          color:
                            poll.status === "open"
                              ? "green"
                              : poll.status === "closed"
                              ? "red"
                              : "black",
                        }}
                      >
                        Status: {poll.status}
                      </p>
                    </div>
                    {userId !== polluserId && poll && (
                      <Button
                        variant="primary"
                        onClick={() => handleFollowToggle(poll.createdBy._id)}
                      >
                        {followStatus[poll.createdBy._id]
                          ? "Unfollow"
                          : "Follow"}
                      </Button>
                    )}
                  </Card.Header>
                  <Card.Text>
                    <div className="mt-3 mb-3">{poll.question}</div>
                    <Card className="mb-3">
                      <Card.Body>
                        <Card.Header className="d-flex justify-content-between">
                          <p>Poll Ends on {poll.expirationTime}</p>
                          <p>
                            Category:{" "}
                            {poll.category &&
                              poll.category.map((item) => item.category_name)}
                          </p>
                        </Card.Header>
                        <Card.Text>
                          {poll && !hasVoted[poll._id]
                            ? poll.options?.map((option, index) => (
                                <div key={index}>
                                  <input
                                    type="radio"
                                    id={`option-${index + 1}`}
                                    name="options"
                                    value={option.option}
                                    onChange={() => handleOptionChange(index)}
                                  />
                                  <label htmlFor={`option-${index + 1}`}>
                                    {option.option} {optionscount?.[index] || 0}
                                  </label>
                                  <span>{option.count}</span>
                                </div>
                              ))
                            : poll.options?.map((item, index) => (
                                <div
                                  key={index}
                                  style={{ position: "relative" }}
                                >
                                  <ProgressBar
                                    now={calculatePercentage(
                                      item.count,
                                      poll.options
                                    )}
                                    style={{
                                      height: "25px",
                                      cursor: "pointer",
                                      margin:"15px"
                                    }}
                                    variant={
                                      selectedOption === index
                                        ? "success"
                                        : "info"
                                    }
                                    // label={`${calculatePercentage(
                                    //   item.count,
                                    //   totalVotes
                                    // )}%`}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      left: "50%",
                                      top: "50%",
                                      transform: "translate(-50%, -50%)",
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`${calculatePercentage(
                                      item.count,
                                      poll.options
                                    )}%`}
                                  </span>
                                </div>
                              ))}

                          {/* <button
                            onClick={() =>
                              handleVoteToggle(poll._id, selectedOption)
                            }
                          >
                            {hasVoted[poll._id] ? "Unvote" : "Vote"}
                          </button> */}
 {/* <div
  style={{
    display: "flex",
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically
    height: "100%", // Ensure the container takes the full card height if needed
  }}
>
  <Button
    variant="outline-success"
    style={{
      textAlign: "center", // Centers text inside the button
    }}
    onClick={() => handleVoteToggle(poll._id, selectedOption,poll.options)}
  >
    {hasVoted[poll._id] ? "Unvote" : "Vote"}
  </Button>
</div> */}
                          <p>{selectedOption}</p>
                        </Card.Text>

                        <ToastContainer />
                      </Card.Body>
                    </Card>
                  </Card.Text>

                  <Card.Footer className="d-flex justify-content-between">
                    <p>
                      <button
                        // onClick={toggleLike}
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={liked ? solidHeart : regularHeart}
                          style={{
                            color: liked ? "red" : "gray",
                            fontSize: "24px",
                          }}
                          onClick={handleLike}
                        />
                      </button>
                      <span style={{ marginLeft: "8px" }}>
                        total like:
                        {/* {totallike ? totallike[poll._id]:1} */}
                        {totallike[poll._id]}
                      </span>{" "}
                      like
                    </p>

                    <p
                      style={{ cursor: "pointer", color: "blue" }}
                      // onClick={()=>handleViewComment(index)}
                      onClick={() => handleOnepoll(poll._id)}
                    >
                      <i className="bi bi-chat-quote-fill"></i> Comments
                    </p>

                    <p
                      ref={target}
                      onClick={handleShareClick}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-share"></i> Share
                    </p>
                    <Overlay
                      show={showOverlay}
                      target={target.current}
                      placement="top"
                      containerPadding={20}
                      rootClose
                      onHide={() => setShowOverlay(false)}
                    >
                      <Popover id="popover-contained">
                        <Popover.Header as="h3">Share this Poll</Popover.Header>
                        <Popover.Body>
                          <div className="d-flex justify-content-around">
                            <a
                              href="https://www.facebook.com/sharer/sharer.php?u=yourPollLink"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-facebook"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://twitter.com/share?url=yourPollLink&text=Check+out+this+poll"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-twitter"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://www.instagram.com/?url=yourPollLink"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-instagram"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://api.whatsapp.com/send?text=Check%20out%20this%20poll%20https://example.com/poll/123"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-whatsapp"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                          </div>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </Card.Footer>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p>No created polls available.</p>
          )}
        </div>
      )}

      {activeTab === "voted" && (
        <div>
          {votedpolls.length > 0 ? (
            votedpolls.map((poll, index) => (
              <Card className="mb-4" key={index}>
                <Card.Body>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6>Name:{poll.createdBy.user_name}</h6>

                      {/* <p>Status:{poll.status}</p> */}
                      <p
                        style={{
                          color:
                            poll.status === "open"
                              ? "green"
                              : poll.status === "closed"
                              ? "red"
                              : "black",
                        }}
                      >
                        Status: {poll.status}
                      </p>
                    </div>
                    {userId !== polluserId && polls && (
                      <Button
                        variant="primary"
                        onClick={() => handleFollowToggle(poll.createdBy._id)}
                      >
                        {/* {isFollowing ? "Unfollow" : "Follow"} */}
                        {followStatus[poll.createdBy._id]
                          ? "Unfollow"
                          : "Follow"}
                      </Button>
                    )}
                  </Card.Header>
                  <Card.Text>
                    <div className="mt-3 mb-3">{poll.question}</div>
                    <Card className="mb-3">
                      <Card.Body>
                        <Card.Header className="d-flex justify-content-between">
                          <p>Poll Ends on {poll.expirationTime}</p>
                          <p>
                            Category:{" "}
                            {poll.category &&
                              poll.category.map((item) => item.category_name)}
                          </p>
                        </Card.Header>
                        <Card.Text>
                          {poll && !hasVoted[poll._id]
                            ? poll.options?.map((option, index) => (
                                <div key={index}>
                                  <input
                                    type="radio"
                                    id={`option-${index + 1}`}
                                    name="options"
                                    value={option.option}
                                    onChange={() => handleOptionChange(index)}
                                  />
                                  <label htmlFor={`option-${index + 1}`}>
                                    {option.option} {optionscount?.[index] || 0}
                                  </label>
                                  <span>{option.count}</span>
                                </div>
                              ))
                            : poll.options?.map((item, index) => (
                                <div
                                  key={index}
                                  style={{ position: "relative" }}
                                >
                                  <ProgressBar
                                    now={calculatePercentage(
                                      item.count,
                                      poll.options
                                    )}
                                    style={{
                                      height: "20px",
                                      cursor: "pointer",
                                      margin:"15px"
                                    }}
                                    variant={
                                      selectedOption === index
                                        ? "success"
                                        : "info"
                                    }
                                    // label={`${calculatePercentage(
                                    //   item.count,
                                    //   totalVotes
                                    // )}%`}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      left: "50%",
                                      top: "50%",
                                      transform: "translate(-50%, -50%)",
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {`${calculatePercentage(
                                      item.count,
                                      poll.options
                                    )}%`}
                                  </span>
                                </div>
                              ))}
                          {/* <button
                            onClick={() =>
                              handleVoteToggle(poll._id, selectedOption)
                            }
                          >
                            {hasVoted[poll._id] ? "Unvote" : "Vote"}
                          </button> */}
                          {/* <div
  style={{
    display: "flex",
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically
    height: "100%", // Ensure the container takes the full card height if needed
  }}
>
  <Button
    variant="outline-success"
    style={{
      textAlign: "center", // Centers text inside the button
    }}
    onClick={() => handleVoteToggle(poll._id, selectedOption,poll.options)}
  >
    {hasVoted[poll._id] ? "Unvote" : "Vote"}
  </Button>
</div> */}

                          <p>{selectedOption}</p>
                        </Card.Text>

                        <ToastContainer />
                      </Card.Body>
                    </Card>
                  </Card.Text>

                  <Card.Footer className="d-flex justify-content-between">
                    <p>
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        {/* {(poll.createdBy.isLiked).toString()} */}
                        <FontAwesomeIcon
                          icon={liked[poll._id] ? solidHeart : regularHeart}
                          style={{
                            color: liked[poll._id] ? "red" : "gray",
                            fontSize: "24px",
                          }}
                          onClick={() => handleLike(poll._id)}
                        />
                      </button>
                      <span style={{ marginLeft: "8px" }}>
                        {" "}
                        total like:{totallike ? totallike[poll._id] : 1}
                      </span>{" "}
                      {/* Display the like count */}
                      like
                    </p>

                    <p
                      style={{ cursor: "pointer", color: "blue" }}
                      // onClick={()=>handleViewComment(index)}
                      onClick={() => handleOnepoll(poll._id)}
                    >
                      <i className="bi bi-chat-quote-fill"></i> Comments
                    </p>

                    <p
                      ref={target}
                      onClick={handleShareClick}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-share"></i> Share
                    </p>
                    <Overlay
                      show={showOverlay}
                      target={target.current}
                      placement="top"
                      containerPadding={20}
                      rootClose
                      onHide={() => setShowOverlay(false)}
                    >
                      <Popover id="popover-contained">
                        <Popover.Header as="h3">Share this Poll</Popover.Header>
                        <Popover.Body>
                          <div className="d-flex justify-content-around">
                            <a
                              href="https://www.facebook.com/sharer/sharer.php?u=yourPollLink"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-facebook"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://twitter.com/share?url=yourPollLink&text=Check+out+this+poll"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-twitter"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://www.instagram.com/?url=yourPollLink"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-instagram"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://api.whatsapp.com/send?text=Check%20out%20this%20poll%20https://example.com/poll/123"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-whatsapp"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                          </div>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </Card.Footer>
                </Card.Body>
              </Card>
            ))
          ) : (
            <p>No voted polls available.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default Userdetails;
