

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  ProgressBar,
  ToastContainer,
  Popover,
  Overlay,
} from "react-bootstrap";
import { toast } from "react-toastify";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { PageContext } from "../Homepage";

function CategoryComp({
  selectedCategory,
  polls,
  setPolls,
  filteredPolls,
  index,
  polluserId,
  poll,
  pollId,
  createdBy,
  name,
  createdon,
  title,
  status,
  question,
  options,
  optionscount,
  votingPeriod,
  category,
  onPollSubmit,
  onCardClick,
  handleVote,
  domain
}) {
  console.log(filteredPolls);
  // let [filterpoll,setfilterpoll]=useState([])
  // const {categoryId} = useParams();
  // console.log(categoryId)

  // setfilterpoll(filteredPolls)
  // console.log(filterpoll)
  // const newfilteredPolls = polls.filter(poll => poll.categoryId === categoryId);
  // console.log(newfilteredPolls)
  let [
    page,
    setPage,
    pollid,
    setPollid,
    isFollowing,
    setIsFollowing,
    followStatus,
    setFollowStatus,
    totallike,
    setTotallike,
    liked,
    setLiked,
    likeCount,
    setLikeCount,
    likedPolls,
    setLikedPolls,
    selectedOption,
    setSelectedOption,
    showVoteButton,
    setShowVoteButton,
    hasVoted,
    setHasVoted,
    voteResults,
    setVoteResults,
    totalVotes,
    setTotalVotes,
    hasVotedbutton,
    setHasvotedbutton,
  ] = useContext(PageContext);

  // const [selectedOption, setSelectedOption] = useState(null);
  // const [showVoteButton, setShowVoteButton] = useState(false);
  // const [hasVoted, setHasVoted] = useState(true);

  const [showOverlay, setShowOverlay] = useState(false); // State for showing the share overlay
  const target = useRef(null);

  // const [totallike, setTotallike] = useState("");
  // const [liked, setLiked] = useState(false);
  // const [likeCount, setLikeCount] = useState("");

  let userId =
    sessionStorage.getItem("loginuserId") ||
    sessionStorage.getItem("googleuserId");
  console.log("userId:", userId);
  console.log(userId);
  console.log("loginuserid", sessionStorage.getItem("loginuserId"));
  console.log("googleuseriod", sessionStorage.getItem("googleuserId"));

  const handleOptionChange = (index) => {
    if (selectedOption === index) {
      unselectOption(); // Unselect the option if it's already selected
    } else {
      setSelectedOption(index); // Select the option
      setShowVoteButton(true);
    }
  };

  const unselectOption = () => {
    setSelectedOption(null); // Unselect the currently selected option
    setShowVoteButton(false);
  };

  const calculatePercentage = (count, totalVotes) => {
    if (totalVotes === 0) return 0;
    return ((count / totalVotes) * 100).toFixed(2);
  };

  const handleVoteToggle = (poll_id, option) => {
    // console.log(poll_id, userId, option);
    const selectedOptionValue = selectedOption;

    console.log(selectedOptionValue)
    if (selectedOption) {
      sessionStorage.setItem("polloption",selectedOption);
    }
    let polloption=sessionStorage.getItem("polloption")
    console.log(polloption)
    let data={ poll_id: poll_id,
      user_id: userId,
      option: selectedOptionValue ? selectedOptionValue :polloption }
      console.log(data)
    axios
      .post(`${domain}polls/voteonpoll`,data)
      .then((res) => {
        console.log(res.data.message);

        if (res.data.message === "Vote recorded successfully.") {
          alert("Voted");
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: true,
          }));
         
        } else if (
          res.data.message === "Vote removed successfully. Please vote again."
        ) {
          alert("Unvoted");
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: false,
          }));
        } else {
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: false,
          }));
        }

        // handleOnepoll(poll_id);
      });
  };

  useEffect(() => {
    if (poll && poll.voters) {
      setHasVoted((prevState) => ({
        ...prevState,
        [poll._id]: poll.voters.some((voter) => voter._id === userId),
      }));
    }
  }, [poll, userId]);

  useEffect(() => {
    if (poll && poll.createdBy) {
      setLiked((prevState) => ({
        ...prevState,
        [poll._id]: poll.createdBy.isLiked,
      }));

      setTotallike((prev) => ({
        ...prev,
        [poll._id]: poll.total_likes,
      }));
    }

    //console.log(liked[poll._id]);
    //console.log({[poll._id]:poll.total_likes});

    console.log(totallike);
  }, [poll]);

  // const toggleLike = () => {
  //   setLikeCount(liked ? likeCount - 1 : likeCount + 1);
  //   setLiked(!liked);
  // };

  const handleLike = (id) => {
    //console.log(createdBy._id);
    // console.log(id)

    axios
      .post(`${domain}polls/likeonpoll`, {
        poll_id: id,
        user_id: userId,
      })

      .then((res) => {
        console.log(res.data);
        setTotallike((prev) => ({
          ...prev,
          [id]: res.data.Total_likes,
        }));
        if (res.data.message === "Like recorded successfully") {
          setLiked((prevState) => ({
            ...prevState,
            [id]: true,
          }));
        } else if (res.data.message === "Like removed successfully") {
          setLiked((prevState) => ({
            ...prevState,
            [id]: false,
          }));
        }
      })
      .catch((err) => {
        console.error("Error in Liking a poll", err);
      });
  };

  let handleOnepoll = (_id) => {
    console.log(_id);
    // navigate('/onepoll/'+_id
    setPage("CommentsComp");
    console.log(page);
    setPollid(_id);
    console.log(page, pollid);
  };

  const handleShareClick = () => {
    setShowOverlay(!showOverlay);
  };

  const handleFollowToggle = (polluserId) => {
    const isFollowing = followStatus[polluserId] || false;

    axios
      .post(`${domain}api/follow`, {
        follow_user_id: polluserId,
        user_id: userId,
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.message === "Follower added successfully") {
          setFollowStatus((prevState) => ({
            ...prevState,
            [polluserId]: true,
          }));
        } else if (res.data.message === "Follower removed successfully") {
          setFollowStatus((prevState) => ({
            ...prevState,
            [polluserId]: false,
          }));
        }
      })
      .catch((err) => {
        console.error("Error in follow/unfollow request:", err);
      });
  };

  return (
    <>
      <div className="category-container">
        {/* <h3>Category:{filteredPolls.category_name}</h3> */}
        {/* <h2 className="category-title text-center">{polls.category_name}</h2>` */}
        {filteredPolls && filteredPolls.length > 0 ? (
          <div>
            {filteredPolls.map((poll) => (
              <Card key={poll._id} className="poll-card h-100">
                <Card.Body>
                  <Card.Header className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6>Name:{poll.createdBy.user_name}</h6>
                      {/* <p>Status:{poll.status}</p> */}
                      <p
                        style={{
                          color:
                            poll.status === "open"
                              ? "green"
                              : poll.status === "closed"
                              ? "red"
                              : "black",
                        }}
                      >
                        Status: {poll.status}
                      </p>
                    </div>
                    {userId !== poll.createdBy._id && poll && (
                      <Button
                        variant="primary"
                        onClick={() => handleFollowToggle(poll.createdBy._id)}
                      >
                        {/* {isFollowing ? "Unfollow" : "Follow"} */}
                        {followStatus[poll.createdBy._id]
                          ? "Unfollow"
                          : "Follow"}
                      </Button>
                    )}
                  </Card.Header>
                  <Card.Text>
                    <div className="mt-3 mb-3">Question: {poll.question}</div>
                    <Card className="mb-3">
                      <Card.Body>
                        <Card.Header className="d-flex justify-content-between">
                          <p>Poll Ends on {poll.expirationTime}</p>
                          <p>
                            Category:{" "}
                            {poll.category &&
                              poll.category.map((item) => item.category_name)}
                          </p>
                        </Card.Header>
                        <Card.Text>
                          <div>
                            {poll && !hasVoted[poll._id]
                              ? poll.options?.map((item, index) => (
                                  <div key={index}>
                                    <input
                                      type="radio"
                                      id={`option-${index}`}
                                      name="poll-option"
                                      value={item.option}
                                      onChange={() =>
                                        handleOptionChange(item.option)
                                      } style={{margin:"10px"}}
                                    />
                                    <label htmlFor={`option-${index}`} style={{paddingLeft:"15px" }}>
                                      {item.option}
                                    </label>
                                    {/* <span>{item.count}</span> */}
                                  </div>
                                ))
                              : poll.options?.map((item, index) => (
                                  <div
                                    key={index}
                                    style={{ position: "relative" }}
                                  >
                                    <ProgressBar
                                      now={calculatePercentage(
                                        item.count,
                                        totalVotes
                                      )}
                                      style={{
                                        height: "20px",
                                        cursor: "pointer",
                                        margin:"15px"
                                      }}
                                      variant={
                                        selectedOption === index
                                          ? "success"
                                          : "info"
                                      }
                                      // label={`${calculatePercentage(
                                      //   item.count,
                                      //   totalVotes
                                      // )}%`}
                                    />
                                    <span
                                      style={{
                                        
                                        position: "absolute",
                                        left: "50%",
                                        top: "50%",
                                        transform: "translate(-50%, -50%)",
                                        color: "black",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {calculatePercentage(
                                        item.count,
                                        totalVotes
                                      )}
                                      %
                                    </span><span style={{marginLeft:"15px"}}>{item.option}({ item.count}) </span>
                                  </div>
                                ))}

                            {/* {hasVoted[onepoll._id] && (
                        <button
                          onClick={() =>
                            handleVoteToggle(onepoll._id, selectedOption)
                          }
                        >
                          Unvote
                        </button>
                      )}

                      <p>{selectedOption}</p>
                      <p>{onepoll.createdAt}</p>
                      <button
                        onClick={() =>
                          handleVoteToggle(onepoll._id, selectedOption)
                        }
                      >
                        Vote
                      </button> */}
                      <div
  style={{
    display: "flex",
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically
    height: "100%", // Ensure the container takes the full card height if needed
  }}
>
  <Button
    variant="outline-success"
    style={{
      textAlign: "center", // Centers text inside the button
    }}
    onClick={() =>handleVoteToggle(poll._id, selectedOption)}
  >
    {hasVoted[poll._id] ? "Unvote" : "Vote"}
  </Button>
</div>
                            {/* <Button
                              onClick={() =>
                                handleVoteToggle(poll._id, selectedOption)
                              }
                            >
                              {hasVoted[poll._id] ? "Unvote" : "Vote"}
                            </Button> */}

                            <p>{selectedOption}</p>
                          </div>
                        </Card.Text>
                        <ToastContainer />
                      </Card.Body>
                    </Card>
                  </Card.Text>

                  <Card.Footer className="d-flex justify-content-between">
                    <p>
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        {/* {poll._id} */}
                        {/* {(poll.createdBy.isLiked).toString()} */}
                        <FontAwesomeIcon
                          icon={liked[poll._id] ? solidHeart : regularHeart}
                          style={{
                            color: liked[poll._id] ? "red" : "gray",
                            fontSize: "24px",
                          }}
                          onClick={() => handleLike(poll._id)}
                        />
                      </button>
                      <span style={{ marginLeft: "8px" }}>
                        {" "}
                        total like:{poll.total_likes}
                      </span>{" "}
                      {/* Display the like count */}
                      like
                    </p>

                    <p
                      style={{ cursor: "pointer", color: "blue" }}
                      // onClick={()=>handleViewComment(index)}
                      onClick={() => handleOnepoll(poll._id)}
                    >
                      <i className="bi bi-chat-quote-fill"></i> Comments
                    </p>

                    <p
                      ref={target}
                      onClick={handleShareClick}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-share"></i> Share
                    </p>
                    <Overlay
                      show={showOverlay}
                      target={target.current}
                      placement="top"
                      containerPadding={20}
                      rootClose
                      onHide={() => setShowOverlay(false)}
                    >
                      <Popover id="popover-contained">
                        <Popover.Header as="h3">Share this Poll</Popover.Header>
                        <Popover.Body>
                          <div className="d-flex justify-content-around">
                            <a
                              href="https://www.facebook.com/sharer/sharer.php?u=yourPollLink"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-facebook"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://twitter.com/share?url=yourPollLink&text=Check+out+this+poll"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-twitter"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://www.instagram.com/?url=yourPollLink"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-instagram"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://api.whatsapp.com/send?text=Check%20out%20this%20poll%20https://example.com/poll/123"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-whatsapp"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            {/* Add more social media links here */}
                          </div>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </Card.Footer>
                </Card.Body>
              </Card>
            ))}
          </div>
        ) : (
          <p className="no-polls-message text-center">
            No polls available for this category
          </p>
        )}
      </div>
    </>
  );
}

export default CategoryComp;
