import { React, createContext } from "react";
import Homepage from "./Components/Homepage";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import CommentsComp from "./Components/Common/CommentsComp";
import Frontpg from "./Frontpage";
import Signup from "./Signup";
import Loginpg from "./Loginpg";
import VerifyPg from "./Verifypg";
import Newpassword from "./Newpassword";
import Onepoll from "./Components/Onepoll";
import { useState } from "react";
import Newpasswordph from "./Newpasswordph";
export let PageContext = createContext();


const isAuthenticated = () => {
  const userId =
    sessionStorage.getItem("loginuserId") || sessionStorage.getItem("googleuserId");
  return !!userId; 
};

const ProtectedRoute = ({ element }) => {
  return isAuthenticated() ? element : <Navigate to="/" />;
};

function App() {
  let domain="https://api.pollingbooth.in/"
  //  let domain="http://49.204.232.254:92/"

  return (
    <div>
      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Frontpg domain={domain}/>} />
          <Route path="/Signup" element={<Signup domain={domain} />} />
          <Route path="/Loginpg" element={<Loginpg domain={domain} />} />
          <Route path="/VerifyPg" element={<VerifyPg domain={domain}/>} />
          <Route path="/newpasswordph/:number" element={<Newpasswordph domain={domain}/>} />
        
          <Route path="/Loginpg" element={<Loginpg domain={domain}/>} />

          <Route path="/Homepage" element={<ProtectedRoute element={<Homepage domain={domain}/>} />} />
        <Route path="/newpassword/:number" element={<ProtectedRoute element={<Newpassword domain={domain}/>} />} />
        <Route path="/viewcomment" element={<ProtectedRoute element={<CommentsComp domain={domain}/>} />} />
        <Route path="/onepoll/:id" element={<ProtectedRoute element={<Onepoll domain={domain}/>} />} />

          
        </Routes>
      </BrowserRouter>
     
    </div>
  );
}

export default App;