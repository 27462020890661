

import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  ProgressBar,
  Overlay,
  Popover,
  Form,
  Modal,
  ToastContainer,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { useContext } from "react";
import { PageContext } from "../Homepage";
import { toast } from "react-toastify";

function CommentsComp({ poll, polluserId, createdBy, optionscount,polltotalVotes,domain }) {
  // console.log(polltotalVotes)
  const [
    page,
    setPage,
    pollid,
    setPollid,
    isFollowing,
    setIsFollowing,
    followStatus,
    setFollowStatus,
    totallike,
    setTotallike,
    liked,
    setLiked,
    likeCount,
    setLikeCount,
    likedPolls,
    setLikedPolls,
    selectedOption,
    setSelectedOption,
    showVoteButton,
    setShowVoteButton,
    hasVoted,
    setHasVoted,
    voteResults,
    setVoteResults,
    totalVotes,
    setTotalVotes,
    hasVotedbutton,
    setHasvotedbutton,
  ] = useContext(PageContext);
 
  // const [totallike, setTotallike] = useState(0);
  // const [liked, setLiked] = useState(false);
  // const [likeCount, setLikeCount] = useState("");

  // const [totallike, setTotallike] = useState(0);
  // const [liked, setLiked] = useState(false);
  // const [likeCount, setLikeCount] = useState("");

  // const [selectedOption, setSelectedOption] = useState(null);
  // const [showVoteButton, setShowVoteButton] = useState(false);
  // const [hasVoted, setHasVoted] = useState(false);
// console.log(domain)
  let [onepoll, setOnepoll] = useState("");

  // const [showVoteButton, setShowVoteButton] = useState(false);
  // const [selectedOption, setSelectedOption] = useState(
  //   onepoll.options.map((option) => option.option)
  // );
  // const [hasVoted, setHasVoted] = useState(onepoll.createdBy.isVoted);
  // let [hasVotedbutton, setHasvotedbutton] = useState(
  //   onepoll.createdBy.isVoted ? "unvote" : "vote"
  // );
  // const [voteResults, setVoteResults] = useState([]);
  // const [totalVotes, setTotalVotes] = useState(onepoll.total_votes);

  const [showOverlay, setShowOverlay] = useState(false);
  const target = useRef(null);

  let [error, setError] = useState("");
  let [loading, setLoading] = useState(true);

  const [newComment, setNewComment] = useState("");
  const [comments, setComments] = useState([]);
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [newReply, setNewReply] = useState("");
  const [currentCommentId, setCurrentCommentId] = useState(null);
  const [likedComments, setLikedComments] = useState({});
  const [likedReplies, setLikedReplies] = useState({});

  // Added state for nested replies
  const [replyToReplyId, setReplyToReplyId] = useState(null); // New state for nested replies
  const [showNestedReplyModal, setShowNestedReplyModal] = useState(false); // State for nested reply modal
  const [newNestedReply, setNewNestedReply] = useState(""); // State for nested reply

  // const [isFollowing, setIsFollowing] = useState(false);

  // console.log(polluserId);
  // console.log(pollid);
  let userId =
    sessionStorage.getItem("loginuserId") ||
    sessionStorage.getItem("googleuserId");

  

  useEffect(() => {
    if (pollid) {
      fetchPollDataAndComments();
    }
  }, [pollid]);

  const fetchPollDataAndComments = async () => {
    try {
      const pollResponse = await axios.post(
       `${domain}polls/getone`,
        {
          poll_id: pollid,
          user_id:userId
        }
      );
      setOnepoll(pollResponse.data);
       console.log(pollResponse.data);

      // await fetchComments();
    } catch (error) {
      setError("Error fetching poll data");
    } finally {
      setLoading(false);
    }
  };
  // console.log(onepoll);
  const handleAddComment = async () => {
    if (newComment.trim() === "") return;

    try {
      const response = await axios.post(
        `${domain}comment/createcomment`,
        {
          comment: newComment,
          poll_id: onepoll._id,
          user_id: userId,
        }
      );
      setComments((prev) => [...prev,response.data.comment ]);
      setNewComment("");
      // fetchComments();
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  const handleLikeComment = async (index, commentId) => {
    const updatedComments = [...comments];
    updatedComments[index].likers.push(userId);
    setComments(updatedComments);

    try {
      await axios.post(`${domain}comment/likecomment`, {
        user_id: userId,
        // comment_id: currentCommentId,
        comment_id: commentId,
      });
    } catch (error) {
      console.error("Error liking comment:", error);
    }
  };

  const handleOpenReplyModal = (commentId) => {
    setCurrentCommentId(commentId);
    setShowReplyModal(true);
  };

  const handleAddReply = async () => {
    if (newReply.trim() === "") return;

    try {
      const response = await axios.post(
        `${domain}comment/replycomment`,
        {
          reply_msg: newReply,
          poll_id: onepoll._id,
          user_id: userId,
          comment_id: currentCommentId,
        }
      );

      const updatedComments = comments?.map((comment) =>
        comment._id === currentCommentId
          ? {
              ...comment,
              replies: [...comment.replies, response.data.reply],
            }
          : comment
      );

      setComments(updatedComments);
      // console.log("nestedreply", updatedComments);
      setNewReply("");
      setShowReplyModal(false);
    } catch (error) {
      console.error("Error adding reply:", error);
    }
  };

  const handleOpenNestedReplyModal = (commentId, replyId) => {
    setCurrentCommentId(commentId);
    setReplyToReplyId(replyId);
    setShowNestedReplyModal(true);
  };

  const handleAddNestedReply = async () => {
    if (newNestedReply.trim() === "") return;

    try {
      const response = await axios.post(
        `${domain}comment/replycomment`,
        {
          reply_msg: newNestedReply,
          poll_id: onepoll._id,
          user_id: userId,
          comment_id: currentCommentId,
          // reply_to_reply_id: replyToReplyId,
        }
      );

      const updatedComments = comments.map((comment) =>
        comment._id === currentCommentId
          ? {
              ...comment,
              replies: comment.replies.map((reply) =>
                reply._id === replyToReplyId
                  ? {
                      ...reply,
                      replies: [...reply.replies, response.data.reply],
                    }
                  : reply
              ),
            }
          : comment
      );

      setComments(updatedComments);
      setNewNestedReply("");
      setShowNestedReplyModal(false);
      // console.log(comments);
    } catch (error) {
      console.error("Error adding nested reply:", error);
    }
  };

  // Added function to handle likes on replies
  const handleLikeReply = async (commentId, replyId) => {
    const updatedComments =
      comments &&
      comments.map((comment) =>
        comment._id === commentId
          ? {
              ...comment,
              replies:
                comment &&
                comment.replies.map((reply) =>
                  reply._id === replyId
                    ? {
                        ...reply,

                        likers: [...reply.likers, userId],
                      }
                    : reply
                ),
            }
          : comment
      );
    setComments(updatedComments);
    // console.log("updatedComments", updatedComments);

    try {
      await axios.post(`${domain}comment/likereply`, {
        user_id: userId,
        comment_id: commentId,
        reply_id: replyId,
      });
    } catch (error) {
      console.error("Error liking reply:", error);
    }
  };

  // const fetchComments = async () => {
  //   try {
  //     const response = await axios.post(
  //       "http://49.204.232.254:64/comment/getbyid",
  //       {
  //         poll_id: onepoll._id,
  //         user_id: onepoll.createdBy._id,
  //       }
  //     );
  //     console.log(response.data);
  //     setComments(response.data);
  //   } catch (error) {
  //     console.error("Error fetching comments:", error);
  //   }
  // };
  const fetchComments = async () => {
    try {
      const response = await axios.post(
        `${domain}comment/getbyid`,
        {
          poll_id: onepoll._id,
          user_id: userId,
        }
      );
      // console.log(response.data);
      setComments(response.data);
    } catch (error) {
      console.error("Error fetching comments:", error);
    }
  };
  useEffect(() => {
    if (onepoll) {
      fetchComments();
    }
  }, [onepoll, comments]); //added dependency here

  const handleOptionChange = (index) => {
    if (selectedOption === index) {
      unselectOption(); // Unselect the option if it's already selected
    } else {
      setSelectedOption(index); // Select the option
      setShowVoteButton(true);
    }
  };
  const unselectOption = () => {
    setSelectedOption(null); // Unselect the currently selected option
    setShowVoteButton(false);
  };

  const fetchTotalVotes = () => {
    axios
      .post(`${domain}polls/totalvote`, {
        poll_id: onepoll._id,
      })
      .then((response) => {
        console.log(response.data);
        const updatedVoteResults = response.data.results || [];
        const updatedTotalVotes = response.data.totalVotes || 0;

        setVoteResults(updatedVoteResults);
        setTotalVotes(updatedTotalVotes);
        // console.log(totalVotes);
        // console.log(updatedTotalVotes);
        // console.log(updatedVoteResults);
      })
      .catch((error) => {
        console.error("Error fetching total votes:", error);
      });
  };
  // const calculatePercentage = (index) => {
  //   console.log("option Index", index);
  //   console.log(optionscount);
  //   console.log("total Votes", totalVotes);

  //   if (totalVotes === 0) return 0;
  //   // const votesForOption = voteResults[optionIndex] || 0;
  //   const votesForOption =
  //     optionscount && optionscount[index] ? optionscount[index] : 0;
  //   console.log("votesForOption", votesForOption);

  //   // return ((votesForOption / totalVotes) * 100).toFixed(1);

  //   const percentage = ((votesForOption / totalVotes) * 100).toFixed(1);
  //   console.log("percentage", percentage);
  //   return percentage;
  // };
  const calculatePercentage = (count) => {
    // console.log("count:", count, "totalVotes:", onepoll.total_votes); 
    const validCount = Number(count) || 0;        
    const validTotalVotes = Number(onepoll.total_votes) || 0;
  
    if (validTotalVotes === 0) {
      return 0;
    }
  
    return ((validCount / validTotalVotes) * 100).toFixed(2); 
  };

  const handleVoteToggle = (poll_id, option) => {
    // console.log(poll_id, userId, option);
    // const selectedOptionValue = onepoll.options[selectedOption];
    const selectedOptionValue =selectedOption
    console.log(selectedOptionValue)
    if (selectedOption) {
      sessionStorage.setItem("polloption", selectedOption);
    }
    let polloption=sessionStorage.getItem("polloption")
    console.log(polloption)
    let data={ poll_id: onepoll._id,
      user_id: userId,
      option: selectedOptionValue ? selectedOptionValue :polloption }
      console.log(data)
    axios
      .post(`${domain}polls/voteonpoll`, data)
      .then((res) => {
        // console.log(res.data.message);

        if (res.data.message === "Vote recorded successfully.") {
          alert("Voted");
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: true,
          }));
        } else if (
          res.data.message === "Vote removed successfully. Please vote again."
        ) {
          alert("Unvoted");
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: false,
          }));
          sessionStorage.removeItem("polloption")
        } else {
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: false,
          }));
        }

        // handleOnepoll(poll_id);
      });
  };

  useEffect(() => {
    if (onepoll) {
      setHasVoted((prevState) => ({
        ...prevState,
        [onepoll._id]: onepoll.voters && onepoll.voters.some((voter) => voter._id === userId),
      }));
    }
  }, [onepoll, userId]);

  //     else {

  //       toast.info('Your vote is removed successfully');
  //       setHasVoted(false);
  //       setSelectedOption("");
  // };

  // let handleVoteToggle=()=>{
  //   console.log(selectedOption,hasVoted)

  //     const selectedOptionValue = options[selectedOption]; // Get the value of the selected option
  // console.log(selectedOptionValue)

  // console.log(_id, createdBy._id, selectedOptionValue)
  //       axios.post('http://49.204.232.254:64/polls/voteonpoll',{

  //       poll_id: _id,
  //       user_id: userId,
  //        option: selectedOptionValue,
  //       })
  //       .then(response => {
  //         // toast.success('Your vote is successfully registered');
  //         console.log( response.data);

  //       })
  //       .catch(error => {
  //         console.error('Error submitting vote:', error);
  //       });
  // };

  const handleShareClick = () => {
    setShowOverlay(!showOverlay);
  };

  const onBackClick = () => {
    window.location.href = "/Homepage";
  };

  useEffect(() => {
    if (onepoll && onepoll.createdBy) {
      setLiked((prevState) => ({
        ...prevState,
        [onepoll._id]: onepoll.createdBy.isLiked,
      }));

      setTotallike((prev) => ({
        ...prev,
        [onepoll._id]: onepoll.total_likes,
      }));
    }

    // console.log({ [onepoll._id]: onepoll.total_likes });
    console.log(totallike);
  }, [poll]);

  // const toggleLike = () => {
  //   setLikeCount(liked ? likeCount - 1 : likeCount + 1);
  //   setLiked(!liked);
  // };

  const handleLike = (id) => {
    //console.log(createdBy._id);
    // console.log(id)

    axios
      .post(`${domain}polls/likeonpoll`, {
        poll_id: id,
        user_id: userId,
      })

      .then((res) => {
        // console.log(res.data);
        setLikeCount(res.data.Total_likes);
        if (res.data.message === "Like recorded successfully") {
          setLiked((prevState) => ({
            ...prevState,
            [id]: true,
          }));
        } else if (res.data.message === "Like removed successfully") {
          setLiked((prevState) => ({
            ...prevState,
            [id]: false,
          }));
        }
      })
      .catch((err) => {
        console.error("Error in Liking a poll", err);
      });
  };

  const toggleCommentLike = (index) => {
    setLikedComments((prev) => {
      const updated = {
        ...prev,
        [index]: !prev[index],
      };
      console.log(updated); // Logs the updated state immediately
      return updated;
    });
  };
  

  const toggleReplyLike = (commentId, replyId) => {
    setLikedReplies((prev) => ({
      ...prev,
      [commentId]: {
        ...prev[commentId],
        [replyId]: !prev[commentId]?.[replyId],
      },
    }));
  };

  // const handleFollowToggle = () => {
  //   console.log("Created By:", createdBy);
  //   console.log("User ID:", userId);
  //   console.log("Poll User ID:", pollid);

  //   axios
  //     .post("http://49.204.232.254:64/api/follow", {
  //       user_id: userId,
  //       follow_user_id: onepoll.createdBy._id,
  //     })
  //     .then((response) => {
  //       console.log("API Response:", response);
  //       console.log("Response Data:", response.data);

  //       if (response.data.message === "Follower added successfully") {
  //         setIsFollowing(true);
  //         toast.success("Followed successfully", { autoClose: 1000 });
  //       } else if (response.data.message === "Follower removed successfully") {
  //         setIsFollowing(false);
  //         toast.info("Unfollowed successfully", { autoClose: 1000 });
  //       } else {
  //         toast.warn("Unable to follow Yourself", { autoClose: 1000 });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error following/unfollowing user:", error);
  //       toast.error("An error occurred. Please try again.", {
  //         autoClose: 3000,
  //       });
  //     });
  // };
  useEffect(() => {
    if (onepoll && onepoll.createdBy) {
      setFollowStatus((prevState) => ({
        ...prevState,
        [onepoll.createdBy._id]: onepoll.createdBy.isFollowing,
      }));
    }
  }, [onepoll]);

  const handleFollowToggle = (polluserId) => {
    const isFollowing = followStatus[polluserId] || false;

    axios
      .post(`${domain}api/follow`, {
        follow_user_id: polluserId,
        user_id: userId,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.message === "Follower added successfully") {
          setFollowStatus((prevState) => ({
            ...prevState,
            [polluserId]: true,
          }));
        } else if (res.data.message === "Follower removed successfully") {
          setFollowStatus((prevState) => ({
            ...prevState,
            [polluserId]: false,
          }));
        }
      })
      .catch((err) => {
        console.error("Error in follow/unfollow request:", err);
      });
  };
  return (
    <>
      <nav
        onClick={onBackClick}
        style={{
          position: "fixed",

          height: "5%",
          width: "46.5%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          color: "black",
          backgroundColor: "lightgrey",
          zIndex: 1000,
          cursor: "pointer",
        }}
      >
        <i class="bi bi-box-arrow-in-left"></i>BACK TO ALL POLLS
      </nav>

      <Card style={{ marginTop: "7%" }}>
        <Card.Body>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <div>
              <h6>Name: {onepoll.createdBy?.user_name || "Unknown"}</h6>
              <p>Created At: {onepoll.createdAt}</p>
              <p>Expiration Time: {onepoll.expirationTime}</p>
              <p>Question: {onepoll.question}</p>
              {/* <p>Status: {onepoll.status}</p> */}
              <p
                style={{
                  color:
                    onepoll.status === "open"
                      ? "green"
                      : onepoll.status === "closed"
                      ? "red"
                      : "black",
                }}
              >
                Status: {onepoll.status}
              </p>
            </div>
            {userId !== polluserId && poll && (
              <Button
                variant="primary"
                onClick={() => handleFollowToggle(onepoll.createdBy._id)}
              >
                {/* {isFollowing ? "Unfollow" : "Follow"} */}
                {followStatus[onepoll.createdBy._id] ? "Unfollow" : "Follow"}
              </Button>
            )}
          </Card.Header>
          <Card.Text>
            <div className="mt-3 mb-3"></div>
            <Card className="mb-3">
              <Card.Body>
                <Card.Header className="d-flex justify-content-between">
                  <p>Poll Ends on {onepoll.expirationTime}</p>
                  <p>
                    Category:{" "}
                    {onepoll.category &&
                      onepoll.category.map((item) => item.category_name)}
                  </p>
                </Card.Header>

                <Card.Body>
                  <Card.Text>
                    <div>
                      {onepoll && !hasVoted[onepoll._id]
                        ? onepoll.options?.map((item, index) => (
                            <div key={index}>
                              <input
                                type="radio"
                                id={`option-${index}`}
                                name="poll-option"
                                value={item.option}
                                onChange={() => handleOptionChange(item.option)} style={{margin:"10px"}}
                              />
                              <label htmlFor={`option-${index}`}>
                                {item.option}
                              </label>
                              {/* <span>{item.count}</span> */}
                            </div>
                          ))
                        : onepoll.options?.map((item, index) => (
                            <div key={index} style={{ position: "relative", padding:"5px" }}>
                              <ProgressBar
                                now={calculatePercentage(
                                  item.count,
                                  totalVotes
                                )}
                                style={{
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                                variant={
                                  selectedOption === index ? "success" : "info"
                                }
                                // label={`${calculatePercentage(
                                //   item.count,
                                //   totalVotes
                                // )}%`}
                              />
                              <span
                                style={{
                                  marginTop:"-16px",
                                  paddingTop:"5px",
                                  position: "absolute",
                                  left: "50%",
                                  top: "50%",
                                  transform: "translate(-50%, -50%)",
                                  color: "black",
                                  fontWeight: "bold",
                                }}
                              >
                                {calculatePercentage(item.count, totalVotes)}%
                              </span> <span>{item.option} ({item.count})</span>
                            </div>
                          ))}

                      {/* {hasVoted[onepoll._id] && (
                        <button
                          onClick={() =>
                            handleVoteToggle(onepoll._id, selectedOption)
                          }
                        >
                          Unvote
                        </button>
                      )}

                      <p>{selectedOption}</p>
                      <p>{onepoll.createdAt}</p>
                      <button
                        onClick={() =>
                          handleVoteToggle(onepoll._id, selectedOption)
                        }
                      >
                        Vote
                      </button> */}
                      <Button
                        onClick={() =>
                          handleVoteToggle(onepoll._id, selectedOption)
                        }
                      >
                        {hasVoted[onepoll._id] ? "Unvote" : "Vote"}
                      </Button>

                      <p>{selectedOption}</p>
                    </div>
                  </Card.Text>
                  <ToastContainer />
                </Card.Body>

                <div>
                  <Card.Footer className="d-flex justify-content-between">
                    <p>
                      <button
                        style={{
                          background: "none",
                          border: "none",
                          cursor: "pointer",
                        }}
                      >
                        {/* {(poll.createdBy.isLiked).toString()} */}
                        <FontAwesomeIcon
                          icon={liked[onepoll._id] ? solidHeart : regularHeart}
                          style={{
                            color: liked[onepoll._id] ? "red" : "gray",
                            fontSize: "24px",
                          }}
                          onClick={() => handleLike(onepoll._id)}
                        />
                      </button>
                      <span style={{ marginLeft: "8px" }}>
                        {" "}
                        total like:{onepoll.total_likes || likeCount}
                      </span>{" "}
                      {/* Display the like count */}
                      like
                    </p>
                    <p
                      ref={target}
                      onClick={handleShareClick}
                      style={{ cursor: "pointer" }}
                    >
                      <i className="bi bi-share"></i> Share
                    </p>
                    <Overlay
                      show={showOverlay}
                      target={target.current}
                      placement="top"
                      containerPadding={20}
                      rootClose
                      onHide={() => setShowOverlay(false)}
                    >
                      <Popover id="popover-contained">
                        <Popover.Header as="h3">Share this Poll</Popover.Header>
                        <Popover.Body>
                          <div className="d-flex justify-content-around">
                            <a
                              href="https://www.facebook.com/sharer/sharer.php?u=yourPollLink"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-facebook"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://twitter.com/share?url=yourPollLink&text=Check+out+this+poll"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-twitter"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://www.instagram.com/?url=yourPollLink"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-instagram"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            &nbsp;&nbsp;
                            <a
                              href="https://api.whatsapp.com/send?text=Check%20out%20this%20poll%20https://example.com/poll/123"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <i
                                className="bi bi-whatsapp"
                                style={{ fontSize: "35px" }}
                              ></i>
                            </a>
                            {/* Add more social media links here */}
                          </div>
                        </Popover.Body>
                      </Popover>
                    </Overlay>
                  </Card.Footer>
                </div>
              </Card.Body>
            </Card>
            <div>
              <h5>Comments</h5>

              {/* Add Comment Input Moved Here */}
              <Form.Control
                type="text"
                placeholder="Add a comment..."
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                className="mb-3"
              />
              <Button
                variant="primary"
                className="mb-3"
                onClick={handleAddComment}
                disabled={!newComment}
              >
                Comment
              </Button>

              {comments.length > 0 &&
                comments.map((comment, index) => (
                  <div key={comment._id} className="mb-3">
                    <Card>
                      <Card.Body>
                        <p>{comment.comment}</p>
                        <p style={{ fontSize: "small", color: "grey" }}>
                          @{comment.user_id.user_name}
                        </p>

                        {/* <p>Likes: {comment.likes.count}</p> */}

                        <button
                          onClick={() => {
                            handleLikeComment(index, comment._id);
                            toggleCommentLike(index);
                          }}
                          style={{
                            background: "none",
                            border: "none",
                            cursor: "pointer",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              likedComments[index] ? solidHeart : regularHeart
                            }
                            style={{
                              color: likedComments[index] ? "red" : "gray",
                              fontSize: "24px",
                            }}
                          />
                        </button>
                        <span>Likes: {comment.likers.length}</span>

                        <Button
                          variant="link"
                          onClick={() => handleOpenReplyModal(comment._id)}
                        >
                          Reply
                        </Button>
                        {comment.replies.length > 0 && (
                          <div style={{ marginLeft: "20px" }}>
                            {comment.replies.map((reply) => (
                              <div key={reply._id} className="mb-2">
                                <Card>
                                  <Card.Body>
                                    <p>{reply.reply_msg}</p>
                                    <p
                                      style={{
                                        fontSize: "small",
                                        color: "grey",
                                      }}
                                    >
                                      @{reply.user_id.user_name}
                                    </p>

                                    <button
                                      onClick={() => {
                                        handleLikeReply(comment._id, reply._id);
                                        toggleReplyLike(comment._id, reply._id);
                                      }}
                                      style={{
                                        background: "none",
                                        border: "none",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={
                                          likedReplies[comment._id]?.[reply._id]
                                            ? solidHeart
                                            : regularHeart
                                        }
                                        style={{
                                          color: likedReplies[comment._id]?.[
                                            reply._id
                                          ]
                                            ? "red"
                                            : "gray",
                                          fontSize: "24px",
                                        }}
                                      />
                                    </button>

                                    <span> {  reply.likers && reply.likers.length > 0 ? `Likes: ${reply.likers.length}`: null}</span>

                                    <Button
                                      variant="link"
                                      onClick={() =>
                                        handleOpenNestedReplyModal(
                                          comment._id,
                                          reply._id
                                        )
                                      }
                                    >
                                      Reply
                                    </Button>
                                    {reply.replies &&
                                      reply.replies.length > 0 && (
                                        <div style={{ marginLeft: "20px" }}>
                                          {reply.replies.map((nestedReply) => (
                                            <div key={nestedReply._id}>
                                              <Card>
                                                <Card.Body>
                                                  <p>
                                                    {" "}
                                                    Nested Reply :{" "}
                                                    {nestedReply.reply_msg}
                                                  </p>
                                                  <p>
                                                    Likes: {nestedReply.likes}
                                                  </p>
                                                  <button
                                                    onClick={() => {
                                                      handleLikeReply(
                                                        comment._id,
                                                        nestedReply._id
                                                      );
                                                      toggleReplyLike(
                                                        comment._id,
                                                        nestedReply._id
                                                      );
                                                    }}
                                                    style={{
                                                      background: "none",
                                                      border: "none",
                                                      cursor: "pointer",
                                                    }}
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={
                                                        likedReplies[
                                                          comment._id
                                                        ]?.[nestedReply._id]
                                                          ? solidHeart
                                                          : regularHeart
                                                      }
                                                      style={{
                                                        color: likedReplies[
                                                          comment._id
                                                        ]?.[nestedReply._id]
                                                          ? "red"
                                                          : "gray",
                                                        fontSize: "24px",
                                                      }}
                                                    />
                                                  </button>
                                                </Card.Body>
                                              </Card>
                                            </div>
                                          ))}
                                        </div>
                                      )}
                                  </Card.Body>
                                </Card>
                              </div>
                            ))}
                          </div>
                        )}
                      </Card.Body>
                    </Card>
                  </div>
                ))}
            </div>
          </Card.Text>
        </Card.Body>
      </Card>

      <Modal show={showReplyModal} onHide={() => setShowReplyModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Reply to Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Enter your reply..."
            value={newReply}
            onChange={(e) => setNewReply(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowReplyModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleAddReply}>
            Reply
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showNestedReplyModal}
        onHide={() => setShowNestedReplyModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Reply to Reply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Control
            type="text"
            placeholder="Enter your reply..."
            value={newNestedReply}
            onChange={(e) => setNewNestedReply(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowNestedReplyModal(false)}
          >
            Close
          </Button>
          <Button variant="primary" onClick={handleAddNestedReply}>
            Reply
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommentsComp;

//-------------------------------
