

import React, { useEffect, useState } from "react";
import axios from "axios";
import CardComp from "./Common/Card";
import "bootstrap/dist/css/bootstrap.min.css";
import CommentsComp from "./Common/CommentsComp";
import CategoryComp from "./Common/CategoryComp";

function Polllist({ page, polls, setPolls, setPage, selectedCategory,domain }) {
  console.log(selectedCategory);
  console.log(page);
  // const [polls, setPolls] = useState([]);
  const [filteredPolls, setFilteredPolls] = useState([]);
  const [currentView, setCurrentView] = useState("cards");
  const [selectedCardData, setSelectedCardData] = useState(null);
  let userId =
  sessionStorage.getItem("loginuserId") ||
  sessionStorage.getItem("googleuserId");
console.log("userId:", userId);
  

  const handlePollSubmit = (newPoll) => {
    setPolls((prevPolls) => [newPoll, ...prevPolls]);
    sessionStorage.setItem("polls", JSON.stringify([newPoll, ...polls]));
  };

  const handleCardClick = (cardData) => {
    console.log(cardData)
    setSelectedCardData(cardData);
    setCurrentView("comments");
  };

  const handleBackClick = () => {
    setCurrentView("cards");
  };

  return (
    <>
      {currentView === "cards" ? (
        polls.length > 0 &&
        polls.map((poll, index) => (
          <CardComp 
            poll={poll}
            polls={polls}
            setPolls={setPolls}
            userId={poll.createdBy._id}
            isUserFollowing={poll.createdBy.isFollowing}
            isVoted={poll.createdBy.isVoted}
            // selectedOption={poll.options.option}
            selectedOption={poll.options.map(option => option.option)}
            totalVotes={poll.total_votes}
            key={index}
            index={index}
            pollId={poll.poll_id}
            polltotalVotes={poll.total_votes}
            polluserId={poll.createdBy._id} //need to check this once
            votingPeriod={poll.expirationTime}
            _id={poll._id}
            title={poll.title}
            category={poll.category.map((cat) => cat.category_name).join(", ")}
            question={poll.question}
            options={poll.options.map((opt) => opt.option)}
            optionscount={poll.options.map((opt) => opt.count)}
            status={poll.status}
            createdBy={poll.createdBy.user_name}
            createdAt={poll.createdAt}
            onPollSubmit={handlePollSubmit}
            onCardClick={() => handleCardClick(poll)}
            domain={domain}
          />
        ))
      ) : (
        polls.map((onepoll,index)=>(
        <CommentsComp
        polltotalVotes={onepoll.total_votes}
         key={index}
         index={index}
          isUserFollowing={onepoll.createdBy.isFollowing}
          userId={onepoll.createdBy._id}
          createdBy={onepoll.createdBy.user_name}
          polluserId={onepoll.createdBy._id}
          cardData={selectedCardData}
          onBackClick={handleBackClick}
          domain={domain}
        />
          
        ))
      )}
    </>
  );
}

export default Polllist;
