

import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { signInWithPopup } from "firebase/auth";
import { auth, provider } from "./Firebase";
import "./Frontpage.css";
import logo from "../src/Images/Pooling logo dem.jpeg";
import pblogo from "../src/Images/8.png";
import { useFormik } from "formik";
import axios from "axios";


const Frontpg = ({domain}) => {
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      // other fields...
    },
    onSubmit: (values) => {
      // handle form submission
    },
  });
  

  let navigate = useNavigate();

  const [displayuser, Setdisplayuser] = useState();

  let [allpoll, setAllpoll] = useState([]);

  useEffect(() => {
    axios.get(`${domain}api/getall`).then((res) => {
      console.log(res.data.users);
      setAllpoll(res.data);
      console.log(allpoll);
    });
  }, []);
  console.log(allpoll);
  const handlegoogle = () => {
    sessionStorage.clear();
    signInWithPopup(auth, provider)
      .then((result) => {
        console.log("User signed in:", result.user.displayName);
        console.log(result.user.email);
        sessionStorage.clear();
        // Store in sessionStorage
        sessionStorage.setItem("username", result.user.displayName);
        sessionStorage.setItem("email", result.user.email);
        handleGooglesignup(result.user.email);
      })
      .catch((error) => {
        console.error("Error signing in with Google:", error);
      });
  };
  // formik.setFieldValue("username", result.user.displayName || "");
  // formik.setFieldValue("email", result.user.email || "");
  let handleGooglesignup = (gmail) => {
    let esistinguser = allpoll.users.some((item) => item.email == gmail);
    let existinggmail=allpoll.users.find(item=>item.email == gmail)
    console.log(existinggmail)
    console.log("gsignup");
    console.log(esistinguser);
    if (!esistinguser) {
      // sessionStorage.clear()
      let user_name = sessionStorage.getItem("username");
      let email = sessionStorage.getItem("email");
      console.log(user_name, email);
      // Navigate to the Signup page
      console.log(user_name, email);
      axios
        .post(`${domain}api/createuser`, {
          user_name: user_name,
          email: email,
          phone_number: user_name,
        })
        .then((res) => {
          console.log(res.data);
          sessionStorage.removeItem("googleuserId")
           sessionStorage.setItem("googleuserId", res.data._id);
          // if(res.data.status === 409){
          //   alert("User already exists")
          // }
          navigate("/homepage");
        });
    } else {
      sessionStorage.setItem("googleuserId",existinggmail._id)
      alert("User already exist");
      navigate("/Homepage");
    }
  };

  return (
    <Row className="bodyy">
      {/* Left Side - Column 6 */}
      <Col sm={6} className="text-center left-column">
        {/* <img src={logo} alt="Polling Logo" className="polling-logoo" /> */}
        <img src={pblogo} alt="Polling Logo" className="polling-logoo" />
      </Col>

      {/* Right Side - Column 6 */}
      <Col sm={6} className="text-center">
        <div className="containerrr">
          <div className="logo"></div>
          <h1>POLLING BOOTH</h1>
          <p>Join today.</p>
          <div className="signup-options">
            <Button
              className="signupp-button"
              variant="primary"
              onClick={handlegoogle}
            >
              {" "}
              Sign up with Google
            </Button>
            <br />
            <Link to="/Signup">
              <Button className="signupp-button" variant="secondary">
                Create account
              </Button>
            </Link>
          </div>
          <div className="login-linkk">
            <p>
              Already have an account? <Link to="/Loginpg" style={{color:"black"}}>Sign in</Link>
            </p>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Frontpg;
