

import React, { useState, useRef, useContext, useEffect } from "react";
import { Button, Card, ProgressBar, Overlay, Popover } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart as solidHeart } from "@fortawesome/free-solid-svg-icons";
import { faHeart as regularHeart } from "@fortawesome/free-regular-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import Onepoll from "../Onepoll";
import CommentsComp from "./CommentsComp";
import { PageContext } from "../Homepage";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

function CardComp({
  index,
  polluserId,
  poll,
  pollId,
  _id,
  createdBy,
  name,
  createdon,
  title,
  status,
  question,
  options,
  polltotalVotes,
  optionscount,
  votingPeriod,
  category,
  onPollSubmit,
  onCardClick,
  handleVote,
  polls,
  setPolls,
  domain
}) {
  let userId =
    sessionStorage.getItem("loginuserId") ||
    sessionStorage.getItem("googleuserId");
  console.log("userId:", userId);
  console.log(userId);
  console.log("loginuserid", sessionStorage.getItem("loginuserId"));
  console.log("googleuseriod", sessionStorage.getItem("googleuserId"));

  const [
    page,
    setPage,
    pollid,
    setPollid,
    isFollowing,
    setIsFollowing,
    followStatus,
    setFollowStatus,
    totallike,
    setTotallike,
    liked,
    setLiked,
    likeCount,
    setLikeCount,
    likedPolls,
    setLikedPolls,
    selectedOption,
    setSelectedOption,
    showVoteButton,
    setShowVoteButton,
    hasVoted,
    setHasVoted,
    voteResults,
    setVoteResults,
    totalVotes,
    setTotalVotes,
    hasVotedbutton,
    setHasvotedbutton,
  ] = useContext(PageContext);

  // let [pollid,setPollid]=useContext(PageContext)
  console.log(polluserId);
  console.log(pollid);
  console.log(optionscount);
  // console.log(votingPeriod);
console.log(polltotalVotes)
  let navigate = useNavigate();
  // let [pollid,setPollid]=useState("")
  console.log(index);
  // let [totallike, setTotallike] = useState(poll.total_likes);
  // const [liked, setLiked] = useState(poll.createdBy.isLiked);
  // const [likeCount, setLikeCount] = useState(poll.total_likes);

  const [comments, setComments] = useState([
    { id: 1, text: "This is the first comment.", likes: 0, replies: [] },
    { id: 2, text: "This is the second comment.", likes: 0, replies: [] },
  ]); // New state for Comments



  const [showOverlay, setShowOverlay] = useState(false);

  const target = useRef(null);



  useEffect(() => {
    if (poll) {
      setHasVoted((prevState) => ({
        ...prevState,
        [poll._id]: poll.voters && poll.voters.some((voter) => voter._id === userId),
      }));
    }
  }, [poll, userId,poll._id]);

  useEffect(() => {
    if (poll && poll.createdBy) {
      setFollowStatus((prevState) => ({
        ...prevState,
        [poll.createdBy._id]: poll.createdBy.isFollowing,
      }));
    }
  }, [poll]);

  console.log("isUserFollowing", poll);


  const handleReply = (id, replyText) => {
    const addReply = (comment) => {
      if (comment.id === id) {
        return {
          ...comment,
          replies: [
            ...comment.replies,
            { id: Date.now(), text: replyText, likes: 0, replies: [] },
          ],
        };
      }
      if (comment.replies && comment.replies.length > 0) {
        return { ...comment, replies: comment.replies.map(addReply) };
      }
      return comment;
    };

    setComments(comments.map(addReply));
  };

  

  const handleOptionChange = (index) => {
    if (selectedOption === index) {
      unselectOption(); 
    } else {
      setSelectedOption(index); 
      setShowVoteButton(true);
    }
  };

  const unselectOption = () => {
    setSelectedOption(null); // Unselect the currently selected option
    setShowVoteButton(false);
  };

   useEffect(() => {
    fetchTotalVotes();
  }, [pollid]);

  const fetchTotalVotes = () => {
    axios
      .post(`${domain}polls/totalvote`, {
        poll_id: _id,
      })
      .then((response) => {
        console.log(response.data);
        const updatedVoteResults = response.data.results || [];
        const updatedTotalVotes = response.data.totalVotes || 0;

        setVoteResults(updatedVoteResults);
        setTotalVotes(updatedTotalVotes);
        console.log(totalVotes);
        console.log(updatedTotalVotes);
        console.log(updatedVoteResults);
      })
      .catch((error) => {
        console.error("Error fetching total votes:", error);
      });
  };

  //this is the stable code for the calculate percentage

  const calculatePercentage = (count) => {
    console.log("count:", count, "totalVotes:", polltotalVotes); 
    const validCount = Number(count) || 0;        
    const validTotalVotes = Number(polltotalVotes) || 0;
  
    if (validTotalVotes === 0) {
      return 0;
    }
  
    return ((validCount / validTotalVotes) * 100).toFixed(2); 
  };

  const handleVoteToggle = (poll_id, option) => {
   


    console.log(poll_id, userId, option);
 
    const selectedOptionValue = options[option]
    console.log(selectedOptionValue)
    if (options[option]) {
      sessionStorage.setItem("polloption", options[option]);
    }
    let polloption=sessionStorage.getItem("polloption")
    console.log(polloption)
    let data={ poll_id: poll_id,
      user_id: userId,
      option: selectedOptionValue ? selectedOptionValue :polloption }
      console.log(data)
    axios
      .post(`${domain}polls/voteonpoll`,data)
      .then((res) => {
        console.log(res.data.message);
  
        if (res.data.message === "Vote recorded successfully.") {
          alert("Voted");
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: true,
          }));
          window.location.reload(); 
          // Refresh the page after voting
        } else if (
          res.data.message === "Vote removed successfully. Please vote again."
        ) {
          alert("Unvoted");
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: false,
          }));
          sessionStorage.removeItem("polloption")
          window.location.reload();
           // Refresh the page after unvoting
        } else {
          setHasVoted((prevState) => ({
            ...prevState,
            [poll_id]: false,
          }));
        }
      
      })
      .catch((error) => {
        console.error("Error in voting:", error);
      });
  };
  



  const getProgressBarColor = (index) => {
    if (selectedOption === index) {
      return "#28a745"; // Selected option will have a green color (or choose any variant)
    } else {
      return "#17a2b8"; // Other options will have a different color
    }
  };

  const handleShareClick = () => {
    setShowOverlay(!showOverlay);
  };

  const handleViewComment = () => {
    onCardClick({
      index,
      name,
      createdon,
      title,
      status,
      question,
      options,
      votingPeriod,
      category,
      likeCount,
      liked,
      comments,
    });
  };
  console.log(options);


  let handleOnepoll = (_id) => {
    console.log(_id);
    // navigate('/onepoll/'+_id
    setPage("CommentsComp");
  
    console.log(page);
    setPollid(_id);
    console.log(page, pollid);
  };

  console.log(poll);

  useEffect(() => {
    if (poll && poll.createdBy && poll.total_likes) {
      setLiked((prevState) => ({
        ...prevState,
        [poll._id]: poll.createdBy.isLiked,
      }));

      setTotallike((prev) => ({
        ...prev,
        [poll._id]: poll.total_likes,
      }));
    }

    console.log(liked[poll._id]);
    console.log({ [poll._id]: poll.total_likes });

    console.log(totallike);
  }, [poll]);

  // const toggleLike = () => {
  //   setLikeCount(liked ? likeCount - 1 : likeCount + 1);

  //  // setLiked(!liked);

  // };


  

  const handleLike = (id) => {
    //console.log(createdBy._id);
    // console.log(id)

    axios
      .post(`${domain}polls/likeonpoll`, {
        poll_id: id,
        user_id: userId,
      })

      .then((res) => {
        console.log(res.data);
        setTotallike((prev) => ({
          ...prev,
          [id]: res.data.Total_likes,
        }));
        if (res.data.message === "Like recorded successfully") {
          setLiked((prevState) => ({
            ...prevState,
            [id]: true,
          }));
        } else if (res.data.message === "Like removed successfully") {
          setLiked((prevState) => ({
            ...prevState,
            [id]: false,
          }));
        }
      })
      .catch((err) => {
        console.error("Error in Liking a poll", err);
      });
  };

  // useEffect(() => {
  //   if (poll) {
  //     setLiked((prevState) => ({
  //       ...prevState,
  //       [poll._id]: poll.createdBy.isLiked,
  //     }));
  //   }
  // }, [poll,userId]);

  // const handleFollowToggle = () => {
  //   console.log("Created By:", createdBy);
  //   console.log("User ID:", userId);
  //   console.log("Poll User ID:", polluserId);

  //   axios
  //     .post("http://49.204.232.254:64/api/follow", {
  //       user_id: userId,
  //       follow_user_id: polluserId,
  //     })
  //     .then((response) => {
  //       console.log("API Response:", response);
  //       console.log("Response Data:", response.data);

  //       if (response.data.message === "Follower added successfully") {
  //         setIsFollowing(true);
  //         toast.success("Followed successfully", { autoClose: 1000 });
  //       } else if (response.data.message === "Follower removed successfully") {
  //         setIsFollowing(false);
  //         toast.info("Unfollowed successfully", { autoClose: 1000 });
  //       } else {
  //         toast.warn("Unable to follow Yourself", { autoClose: 1000 });
  //       }
  //     })
  //     .catch((error) => {
  //       console.error("Error following/unfollowing user:", error);
  //       toast.error("An error occurred. Please try again.", {
  //         autoClose: 3000,
  //       });
  //     });
  // };

  const handleFollowToggle = (polluserId) => {
    const isFollowing = followStatus[polluserId] || false;

    axios
      .post(`${domain}api/follow`, {
        follow_user_id: polluserId,
        user_id: userId,
        action: isFollowing ? "unfollow" : "follow",
      })
      .then((res) => {
        console.log(res.data);
        if (res.data.message === "Follower added successfully") {
          setFollowStatus((prevState) => ({
            ...prevState,
            [polluserId]: true,
          }));
        } else if (res.data.message === "Follower removed successfully") {
          setFollowStatus((prevState) => ({
            ...prevState,
            [polluserId]: false,
          }));
        }
      })
      .catch((err) => {
        console.error("Error in follow/unfollow request:", err);
      });
  };

  return (
    <Card style={{ width: "40rem" }}>
      <Card.Body>
        <Card.Header className="d-flex justify-content-between align-items-center">
          <div>
            <h6>Name:{createdBy}</h6>
            {/* <p>Title:{title}</p> */}
            {/* <p>Status:{status}</p> */}
            <p
              style={{
                color:
                  status === "open"
                    ? "green"
                    : status === "closed"
                    ? "red"
                    : "black",
              }}
            >
              Status: {status}
            </p>
          </div>
          {userId !== polluserId && poll && (
            <Button
              variant="primary"
              onClick={() => handleFollowToggle(poll.createdBy._id)}
            >
              {/* {isFollowing ? "Unfollow" : "Follow"} */}
              {followStatus[poll.createdBy._id] ? "Unfollow" : "Follow"}
            </Button>
          )}
        </Card.Header>

        <Card.Text>
          <div className="mt-3 mb-3">Question: {question}</div>
          <Card className="mb-3">
            <Card.Body>
              <Card.Header className="d-flex justify-content-between">
                <p>Poll Ends on : {poll.expirationTime}</p>
                <p>Category: {category}</p>
              </Card.Header>

              {/* <Card.Text className="d-flex flex-column">
                {options.map((option, index) => (
                  <div key={index}>
                    {selectedOption === index ? (
                      <div>
                        <ProgressBar
                          now={100}
                          label={option}
                          // onClick={unselectOption}
                          onClick={() => setSelectedOption(null)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    ) : (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={`option${index + 1}`}
                          name="options"
                          value={option}
                          onChange={() => handleOptionChange(index)}
                          checked={selectedOption === index}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`option${index + 1}`}
                        >
                          {option}
                        </label>
                      </div>
                    )}
                  </div>
                ))} */}

              {/* Conditionally render the vote button at the end of all options */}

              {/* {selectedOption !== null && (
                  <Button
                    variant={hasVoted ? "primary" : "danger"}
                    onClick={() => handleVoteToggle()}
                    className="mt-3 align-self-center"
                  >
                    {hasVoted ? "Vote" : "Unvote"}
                  </Button>
                )}
              </Card.Text> */}

              {/* this below oneis the stable one dont delete this */}

              {/* <Card.Text className="d-flex flex-column">
                {options?.map((option, index) => (
                  <div key={index} style={{ marginBottom: "10px" }}>
                    {" "}
                
                    {!hasVoted ? (
                      // Show radio buttons when hasn't voted
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={`option${index + 1}`}
                          name="options"
                          value={option}
                          onChange={() => handleOptionChange(index)}
                          checked={selectedOption === index}
                        />
                        <label
                          className="form-check-label"
                          htmlFor={`option${index + 1}`}
                        >
                          {option} ({optionscount[index]})
                        </label>
                      </div>
                    ) : (
                      // Show progress bar with percentage after vote
                      <div style={{ position: "relative" }}>
                        <ProgressBar
                          now={calculatePercentage(index)}
                          style={{
                            height: "20px",
                            cursor: "pointer",
                          }}
                          variant={
                            selectedOption === index ? "success" : "info"
                          } // Selected option is green, others are blue
                          label={`${calculatePercentage(index)}%`}
                        />
                   
                        <span
                          style={{
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >
                          {`${calculatePercentage(index)}%`}{" "}
                        
                        </span>
                      </div>
                    )}
                  </div>
                ))}

              
                {selectedOption !== null && showVoteButton && (
                  <Button
                    variant={!hasVoted ? "primary" : "danger"}
                    // onClick={handleVoteToggle}
                    onClick={() => {
                      handleVoteToggle();
                    }}
                    className="mt-3 align-self-center"
                  >
                    {hasVoted? "Vote" : "Unvote"}
                    {hasVotedbutton}
                  </Button>
                )}
              </Card.Text> */}

              <Card.Text>
                {poll && !hasVoted[poll._id]
                  ? poll.options?.map((option, index) => (
                      <div key={index}>
                        <input
                          type="radio"
                          id={`option-${index + 1}`}
                          name="options"
                          value={option.option}
                          onChange={() => handleOptionChange(index)} style={{marginTop:"15px"}}
                        /> &nbsp;
                        <label htmlFor={`option-${index + 1}`}>
                          {option.option}
                           
                        </label>
                        {/* <span>{option.count}</span> */}
                      </div>
                    ))
                  : poll.options?.map((item, index) => (
                    
                      <div key={index} style={{ position: "relative" }}>
                        
                       <div
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: "5px", // Ensures the container takes the full width
  }}
>&nbsp; &nbsp;
  <ProgressBar
    now={calculatePercentage(item.count, totalVotes)}
    style={{
      marginTop:"15px",
      height: "25px",
      cursor: "pointer",
      width: "80%", 
    // Adjust width as needed
    }}
    variant={selectedOption === index ? "success" : "info"} 
    // label={`${calculatePercentage(item.count, totalVotes)}%`}
  /> 
</div>
                        <span
                          style={{
                            // paddingTop:"15px",
                            position: "absolute",
                            left: "50%",
                            top: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontWeight: "bold",
                          }}
                        >  
                          {`${calculatePercentage(item.count, totalVotes)}%`}  
                        </span >  <span style={{marginLeft:"65px"}}>{item.option}</span> <span style={{marginTop:"0px"}}>({optionscount[index]})</span> 
                      </div>
                    
                    ))} 

           
<br/>
<div
  style={{
    display: "flex",
    justifyContent: "center", // Centers horizontally
    alignItems: "center", // Centers vertically
    height: "100%", // Ensure the container takes the full card height if needed
  }}
>
  <Button
    variant="outline-success"
    style={{
      textAlign: "center", // Centers text inside the button
    }}
    onClick={() => handleVoteToggle(poll._id, selectedOption)}
  >
    {hasVoted[poll._id] ? "Unvote" : "Vote"}
  </Button>
</div>


                {/* <p>{selectedOption}</p> */}
              </Card.Text>
              {/* 
              <Card.Text className="d-flex flex-column">
    {options && options.length > 0 ? (
      options.map((option, index) => (
        <div key={index}>
          {voteResults.length > 0 ? (
            // Display the progress bar with percentage after voting
            <div>
              <ProgressBar
                now={calculatePercentage(voteResults[index]?.votes || 0)}
                label={`${calculatePercentage(voteResults[index]?.votes || 0)}%`}
                style={{ cursor: "pointer" }}
              />
            </div>
          ) : (
            // Render radio buttons before voting
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                id={`option${index + 1}`}
                name="options"
                value={option}
                onChange={() => handleOptionChange(index)}
                checked={selectedOption === index}
              />   */}
              {/* <label className="form-check-label" htmlFor={`option${index + 1}`}> */}
              {/* {option}  */}
              {/* </label> */}

              {/* </div>
          )}
        </div>
      ))
    ) : (
      <p>No options available</p>
    )}

    {selectedOption !== null && voteResults.length === 0 && (
      <Button
        variant={hasVoted ? "primary" : "danger"}
        // onClick={handleVoteToggle}
        onClick={() => {
          handleVoteToggle();
        }}
        className="mt-3 align-self-center"
      >
        {hasVoted ? "Vote" : "Unvote"}
      </Button>
    )}
  </Card.Text>   */}

              <ToastContainer />
            </Card.Body>
          </Card>
        </Card.Text>

        <Card.Footer className="d-flex justify-content-between">
          <p>
            <button
              style={{ background: "none", border: "none", cursor: "pointer" }}
            >
              {/* {(poll.createdBy.isLiked).toString()} */}
              <FontAwesomeIcon
                icon={liked[poll._id] ? solidHeart : regularHeart}
                style={{
                  color: liked[poll._id] ? "red" : "gray",
                  fontSize: "24px",
                }}
                onClick={() => handleLike(poll._id)}
              />
            </button>
            <span style={{ marginLeft: "8px" }}>
              {" "}
              total like:
              {totallike ? totallike[poll._id] : 1}
              {/* {totallike[poll._id]} */}
            </span>{" "}
            {/* Display the like count */}
            like
          </p>

          <p
            style={{ cursor: "pointer", color: "blue" }}
            // onClick={()=>handleViewComment(index)}
            onClick={() => handleOnepoll(_id)}
          >
            <i className="bi bi-chat-quote-fill"></i> Comments
          </p>

          <p
            ref={target}
            onClick={handleShareClick}
            style={{ cursor: "pointer" }}
          >
            <i className="bi bi-share"></i> Share
          </p>
          <Overlay
            show={showOverlay}
            target={target.current}
            placement="top"
            containerPadding={20}
            rootClose
            onHide={() => setShowOverlay(false)}
          >
            <Popover id="popover-contained">
              <Popover.Header as="h3">Share this Poll</Popover.Header>
              <Popover.Body>
                <div className="d-flex justify-content-around">
                  <a
                    href="https://www.facebook.com/sharer/sharer.php?u=yourPollLink"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="bi bi-facebook"
                      style={{ fontSize: "35px" }}
                    ></i>
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href="https://twitter.com/share?url=yourPollLink&text=Check+out+this+poll"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="bi bi-twitter"
                      style={{ fontSize: "35px" }}
                    ></i>
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href="https://www.instagram.com/?url=yourPollLink"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="bi bi-instagram"
                      style={{ fontSize: "35px" }}
                    ></i>
                  </a>
                  &nbsp;&nbsp;
                  <a
                    href="https://api.whatsapp.com/send?text=Check%20out%20this%20poll%20https://example.com/poll/123"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i
                      className="bi bi-whatsapp"
                      style={{ fontSize: "35px" }}
                    ></i>
                  </a>
                  {/* Add more social media links here */}
                </div>
              </Popover.Body>
            </Popover>
          </Overlay>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
}

export default CardComp;

//--------------------------------------------------------------------------------------------------------------------------------------------------------------------------
