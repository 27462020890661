import React, { createContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "./Homepage.css";
import axios from "axios";
import { Modal } from "react-bootstrap";
import {
  Card,
  Button,
  Row,
  Col,
  Form,
  InputGroup,
  ProgressBar,
  Container,
} from "react-bootstrap";
import Polllist from "./Polllist";
import AddPoll from "./AddPoll";
import Pollresults from "./Pollresults";
import Userdetails from "./Userdetails";
import CommentsComp from "./Common/CommentsComp";
// import { PageContext } from "../App";
// import logo from './src/images/logo.png';
import OTPVerificationModal from "./Common/Otpverify";
import { useLocation, useNavigate } from "react-router-dom";
import { getAuth, signOut } from "firebase/auth";
import CategoryComp from "./Common/CategoryComp";
export const PageContext = createContext();
function Homepage({ poll,domain}) {
  let userId =
    sessionStorage.getItem("loginuserId") ||
    sessionStorage.getItem("googleuserId");
  console.log("userId:", userId);
  console.log("loginuserid", sessionStorage.getItem("loginuserId"));
  console.log("googleuseriod", sessionStorage.getItem("googleuserId"));
  let [polls, setPolls] = useState([]);
  let [page, setPage] = useState("Polllist");
  let [pollid, setPollid] = useState("");
  let [totallike, setTotallike] = useState(
    polls.map((poll) => poll.total_likes)
  )
  const [liked, setLiked] = useState( {} );
  const [likeCount, setLikeCount] = useState()
    const [likedPolls, setLikedPolls] = useState([]);
  const [isFollowing, setIsFollowing] = useState(
    polls.map((poll) => poll.createdBy.isFollowing));
  const [followStatus, setFollowStatus] = useState({});
  console.log(isFollowing);
  console.log(polls.map((poll) => poll.createdBy.isLiked));
  const [selectedOption, setSelectedOption] = useState(null);
  const [showVoteButton, setShowVoteButton] = useState(false);
  const [hasVoted, setHasVoted] = useState({});
  console.log("hasVoted", hasVoted);
  const [voteResults, setVoteResults] = useState([]);
  const [totalVotes, setTotalVotes] = useState(polls.total_votes);
  let loginuser = useLocation();
  let newuser = loginuser.state;
  console.log(newuser);
  console.log(loginuser.state);
  const [searchQuery, setSearchQuery] = useState("");
  const [trendingPolls, setTrendingPolls] = useState([]);
  const [googlegmail, setGooglegmail] = useState(
    sessionStorage.getItem("email") || "");
  const [googleusername, setGoogleusername] = useState(
    sessionStorage.getItem("username") || "");
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [step, setStep] = useState(1);
  useEffect(() => {
    if (searchQuery) {
      handleSearch();
    } else {
      fetchPolls();
    }
  }, [searchQuery, userId]);

  const [filteredPolls, setFilteredPolls] = useState([]);
  const fetchPolls = async () => {
    try {
      const response = await axios.post(
        `${domain}polls/getall`,
        {
          user_id: userId,
        }
      );
      console.log(response.data);
      setPolls(response.data);
      sessionStorage.setItem("polls", JSON.stringify(response.data)); 
    } catch (error) {
      console.error("Error fetching polls:", error);
    }
  };
  useEffect(() => {
    if (selectedCategory) {
      console.log("fetch cagegory");
      console.log("Selected Category:", selectedCategory);

      const filteredCategory = polls.filter((poll) =>
        poll.category.some((cat) => cat._id === selectedCategory._id)
      );
      console.log("Filtered Polls:", filteredCategory);
      setFilteredPolls(filteredCategory);
      console.log(filteredPolls);

      setPage("category");
    } else {
      fetchPolls();
    }
  }, [selectedCategory]);


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(
          `${domain}category/getall`
        );
        console.log(response.data);
        setCategories(response.data);
        console.log(categories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const handleCategoryPage = (category) => {
    setPage("category");
    console.log("Selected Category:", category);
    setSelectedCategory(category);
  };
  console.log("Selected Category:", selectedCategory);
  console.log("Selected Category page:", page);
  
  sessionStorage.getItem("username");
  console.log("session data", sessionStorage.getItem("username"));
  const [googleuser, setgoogleuser] = useState(
    sessionStorage.getItem("username")
  );

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handlePhoneChange = (e) => {
    setPhoneNumber(e.target.value);
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
  };
  const onPhoneSubmit = async () => {
    try {
      const response = await axios.post(
        `${domain}mobileauth/send-otp-sms`,
        { number: phoneNumber }
      );
      if (response.status === 200) {
        console.log("OTP sent successfully");
        setStep(2); 
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
    }
  };

  const navigate = useNavigate();

  const handleOtpSubmit = async () => {
    console.log(phoneNumber);
    try {
      const response = await axios.post(
        `${domain}mobileauth/verify-otp-sms`,
        { number: phoneNumber, otp: otp }
      );
      if (response.status === 200) {
        console.log("OTP verified successfully");
        sessionStorage.setItem("verifiedPhoneNumber", phoneNumber);
        sessionStorage.setItem("isVerified", true);
        navigate(`/newpassword/${phoneNumber}`);
      } else {
        console.error("OTP verification failed");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
    }
  };
  console.log(googlegmail, googleusername);
  useEffect(() => {
    axios
      .get(`${domain}polls/top3`)
      .then((response) => {
        setTrendingPolls(response.data);
      })
      .catch((error) => {
        console.error("Error fetching trending polls:", error);
      });
  }, []);

  let handlePageClick = (component) => {
    setPage(component);
    // window.location.reload(); 
    
  };
  let addNewPoll = (newPoll) => {
    setPolls([...polls, newPoll]);
    setPage("Polllist"); 
  };
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value); 
  };

  const handleSearch = async () => {
    console.log(searchQuery);
    try {
      const response = await axios.post(
        `${domain}polls/search`,
        {
          query: searchQuery,
        }
      );
      console.log(response);
      setPolls(response.data.poll_ids);
    } catch (error) {
      console.error("Error searching polls:", error);
    }
  };

  const handleAddPoll = () => {
    const verifiedPhoneNumber = sessionStorage.getItem("verifiedPhoneNumber");
    const isVerified = sessionStorage.getItem("isVerified");

    if (googlegmail && googleusername) {
      if (isVerified && verifiedPhoneNumber) {
        setPage("AddPoll");
      } else {
        handleShow();
      }
    } else {
      setPage("AddPoll");
    }
  };

  const handleSignOut = async () => {
    const auth = getAuth(); 
    try {
      await signOut(auth); 
      sessionStorage.clear(); 
      localStorage.removeItem("authToken"); 
      sessionStorage.clear();
      navigate("/loginpg"); 
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  return (
    <div>
      <PageContext.Provider
        value={[
          page,
          setPage,
          pollid,
          setPollid,
          totallike,
          setTotallike,
          liked,
          setLiked,
          likeCount,
          setLikeCount,
          likedPolls,
          setLikedPolls,

          isFollowing,
          setIsFollowing,
          followStatus,
          setFollowStatus,
          selectedOption,
          setSelectedOption,
          showVoteButton,
          setShowVoteButton,
          hasVoted,
          setHasVoted,
          voteResults,
          setVoteResults,
          totalVotes,
          setTotalVotes,
          // showVoteButton,
          // setShowVoteButton,
          
        ]}
      >
        <div className="polling-booth">
          <header>
            <h1>POLLING BOOTH</h1>
            <input
              type="search"
              placeholder="Search Polls"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <div className="user-info">
              <h4>
                Welcome... {googleuser ? googleuser : newuser}{" "}
                <i class="bi bi-person-circle"></i> !
              </h4>
            </div>
          </header>
          <div className="container">
            <aside>
              <nav>
                <ul>
                  <li onClick={() => handlePageClick("Polllist")}>
                    <i class="bi bi-list"> </i>Poll List
                  </li>
                  <li onClick={handleAddPoll}>
                    <i class="bi bi-plus-circle"> </i> Add Poll
                  </li>
                  <li onClick={() => handlePageClick("Userdetails")}>
                    <i class="bi bi-person-circle"></i> User Details
                  </li>
                </ul>
              </nav>
              <div className="categories">
                <h4>CATEGORIES</h4>
                <nav>
                  {categories.map((cat) => (
                    <button
                      onClick={() => handleCategoryPage(cat)}
                      key={cat.category_id}
                    >
                      {cat.category_name}
                    </button>
                  ))}
                </nav>
                <button className="sign-out" onClick={handleSignOut}>
                  Sign Out <i className="bi bi-box-arrow-right"></i>
                </button>
              </div>
            </aside>
            <main>
              <nav>
                {page === "Polllist" && (
                  <Polllist
                    polls={polls}
                    setPolls={setPolls}
                    selectedCategory={selectedCategory} domain={domain}
                  />
                )}
                {page === "AddPoll" && <AddPoll addNewPoll={addNewPoll} domain={domain} />}
              {page === "Pollresults" && <Pollresults  domain={domain}/>}
                {page === "Userdetails" && <Userdetails  domain={domain}  />}
                {page === "CommentsComp" && <CommentsComp  domain={domain} />}
                {page === "category" && (
                  <CategoryComp
                    polls={polls}
                    setPolls={setPolls}
                    filteredPolls={filteredPolls}
                    domain={domain}
                  />
                )}
              </nav>
            </main>
            <aside className="trending-polls">
              <h4>TRENDING POLLS</h4>
              <hr />
              <nav>
                {trendingPolls.map((poll) => (
                  <Card key={poll._id}>
                    <Card.Body>
                      <Card.Header>Question: {poll.question}</Card.Header>
                      <Card.Text>
                        <p>
                          <i className="bi bi-check2-circle"></i> Total Votes:{" "}
                          {poll.totalVotes}
                        </p>
                        <hr />
                        <p>
                          <i className="bi bi-heart-fill"></i> Total Likes:{" "}
                          {poll.totalLikes}
                        </p>
                      </Card.Text>
                    </Card.Body>
                  </Card>
                ))}
              </nav>
            </aside>
          </div>
          <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
              <Modal.Title>
                {step === 1 ? "Verify Phone Number" : "Verify OTP"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                {step === 1 && (
                  <Form.Group controlId="formPhoneNumber">
                    <Form.Label>Enter Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Phone Number"
                      value={phoneNumber}
                      onChange={handlePhoneChange}
                    />
                  </Form.Group>
                )}
                {step === 2 && (
                  <Form.Group controlId="formOtp">
                    <Form.Label>Enter OTP</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP"
                      value={otp}
                      onChange={handleOtpChange}
                    />
                  </Form.Group>
                )}
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
              <Button
                variant="primary"
                onClick={step === 1 ? onPhoneSubmit : handleOtpSubmit}
              >
                {step === 1 ? "Send OTP" : "Verify OTP"}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </PageContext.Provider>
    </div>
  );
}

export default Homepage;
